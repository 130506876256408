import type { ResponsiveImage } from '~/modules/images/type';
import type { ContextDevice } from '@archipro-design/aria';
import type { TileProps } from '~/modules/articles/type';
import type { ProfessionalTileProps } from '~/modules/professional/type';

export interface HomeTileTrackerData {
    row: number;
    column: number;
    columnPerRow: number;
    position: number;
    sectionOrder: number;
    itemID: number;
    professionalID: number;
    sectionType: HomeSectionType;
}

export interface HomeTileViewModel<T> {
    tile: T;
    id: number;
    link: string;
    title: string;
    subTitle: string;
    about?: string;
    largeImages?: string[]; // For the very large tiles
    headerBanner?: ResponsiveImage;
    professionalID: number;
}

export type HomeSectionType =
    | 'professionals'
    | 'products'
    | 'projects'
    | 'articles';

type HomeSectionColumn = Record<HomeSectionType, number>;

// Section column counts config
export const HOME_SECTION_COLUMNS: Record<ContextDevice, HomeSectionColumn> = {
    desktop: {
        professionals: 3,
        products: 6,
        projects: 3,
        articles: 2, // not include the main tile
    },
    // TODO: placeholder for tablet version home page
    tablet: {
        professionals: 3,
        products: 4,
        projects: 3,
        articles: 3,
    },
    mobile: {
        professionals: 1,
        products: 2,
        projects: 1,
        articles: 1,
    },
};

export interface HomeArticleTileProps {
    tile: TileProps;
    trackerData?: HomeTileTrackerData;
}

export interface ProfessionalHomeTileProps {
    images: ProfessionalTileProps['image'];
    logo: ProfessionalTileProps['logo'];
    title: ProfessionalTileProps['title'];
    category: ProfessionalTileProps['category'];
    memberSince?: ProfessionalTileProps['memberSince'];
    size: ProfessionalTileProps['size'];
    location?: ProfessionalTileProps['location'];
    services: ProfessionalTileProps['services'];
    homePinned?: boolean;
}
