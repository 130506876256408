import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem } from '@archipro-design/aria';

export const GridWrapper: StyleRule = ({ theme }) => ({
    gridGap: pxArrayToRem([70, 6]),
    [theme.screen.tablet]: {
        gridGap: pxArrayToRem([110, 0]),
        margin: pxArrayToRem([0, -10]),
    },
});
