import {
    Grid,
    useStyles,
    Caption,
    Flex,
    Subheader,
    useAppDisplayModeContext,
} from '@archipro-design/aria';

import type { CountryContext } from '@archipro-website/localisation';
import { useCountryContext } from '@archipro-website/localisation';
import { UserLight, BoxLightAlt } from '@archipro-design/icons';

import * as S from './ListYourBusinessModule.style';

const ListYourBusinessModule = () => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    const blocks = blocksMap(useCountryContext());

    return (
        <div className={css(S.BaseStyle)}>
            <Subheader
                className={css(S.SectionHeader)}
            >{`List your business`}</Subheader>
            <Grid columns={!desktop ? 1 : 2} className={css(S.Grid)}>
                {blocks.map((block) => (
                    <Flex column key={block.title} className={css(S.Block)}>
                        <div className={css(S.Icon)}>{block.icon}</div>
                        <Caption
                            variant="03"
                            uc={true}
                            className={css(S.BlockTitle)}
                        >
                            {block.title}
                        </Caption>
                        <Caption variant="03" className={css(S.BlockContent)}>
                            {block.content}
                        </Caption>
                        <a href={block.link} className={css(S.BlockLink)}>
                            {block.linkText}
                        </a>
                    </Flex>
                ))}
            </Grid>
        </div>
    );
};

export default ListYourBusinessModule;

const blocksMap = (countryCtx: CountryContext) => {
    return countryCtx.byCountry({
        AU: [
            {
                title: `PROFESSIONALS`,
                content: `ArchiPro provides you with the opportunity to place your brand in front of the largest and most engaged community within the design and build industry.`,
                link: `/become-a-pro`,
                linkText: `Learn More`,
                icon: <UserLight />,
            },
            {
                title: `MANUFACTURERS / SUPPLIERS`,
                content: `Scale your showroom to reach millions of homeowners and specifiers who use ArchiPro each year to search for products.`,
                link: `/sell-on-archipro`,
                linkText: `Learn More`,
                icon: <BoxLightAlt />,
            },
        ],
        NZ: [
            {
                title: `PROFESSIONALS`,
                content: `ArchiPro provides you with the opportunity to place your brand in front of the largest and most engaged community within the design and build industry.`,
                link: `/become-a-pro`,
                linkText: `Learn More`,
                icon: <UserLight />,
            },
            {
                title: `MANUFACTURERS / SUPPLIERS`,
                content: `Scale your showroom to reach millions of homeowners and specifiers who use ArchiPro each year to search for products.`,
                link: `/sell-on-archipro`,
                linkText: `Learn More`,
                icon: <BoxLightAlt />,
            },
        ],
    });
};
