import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Header: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        fontSize: pxToRem(16),
        marginLeft: pxToRem(915),
        lineHeight: theme.siteVariables.lineHeightSmaller,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        textTransform: 'uppercase',
        ...theme.siteVariables.textStyles.Text.Roman55,
        maxWidth: pxToRem(424),
        marginBottom: pxToRem(30),
    },
    fontSize: pxToRem(32),
    marginLeft: pxToRem(18),
    letterSpacing: theme.siteVariables.letterSpacingNormal,
    color: theme.siteVariables.colorScheme?.default?.foreground,
});
