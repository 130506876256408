import {
    Grid,
    useStyles,
    Caption,
    Flex,
    Subheader,
    useAppDisplayModeContext,
    pxToRem,
} from '@archipro-design/aria';

import { MapPinLine, Professional, SearchLight } from '@archipro-design/icons';

import * as S from './WhyArchiProModule.style';

const WhyArchiProModule = () => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();

    const blocks = [
        {
            title: `Industry leading database`,
            content: `Find thousands of top architects, designers, and suppliers to choose from and meet your specific needs`,
            icon: <SearchLight />,
        },
        {
            title: `Build with confidence`,
            content: `All companies listed on ArchiPro have been vetted through a careful selection process`,
            icon: <Professional />,
        },
        {
            title: `Tailored to your location`,
            content: `Find products that are available in your region or hire Professionals that operate in your area.`,
            icon: <MapPinLine />,
        },
    ];

    return (
        <div className={css(S.BaseStyle)}>
            <Subheader
                className={css(S.SectionHeader)}
            >{`Why ArchiPro?`}</Subheader>
            <Grid
                columns={!desktop ? 1 : `repeat(3, ${pxToRem(270)})`}
                className={css(S.Grid)}
            >
                {blocks.map((block) => (
                    <Flex column key={block.title} className={css(S.Block)}>
                        <div className={css(S.Icon)}>{block.icon}</div>
                        <Caption variant="03" weight={'medium'}>
                            {block.title}
                        </Caption>
                        <Caption variant="03" className={css(S.Content)}>
                            {block.content}
                        </Caption>
                    </Flex>
                ))}
            </Grid>
        </div>
    );
};

export default WhyArchiProModule;
