import { useStyles } from '@archipro-design/aria';
import * as S from './SectionHeader.style';

interface SectionHeaderProps {
    title: string;
    underline?: boolean;
    shortTitle?: boolean;
}

const SectionHeader = ({
    title,
    underline,
    shortTitle,
}: SectionHeaderProps) => {
    const { css } = useStyles({ shortTitle });

    return (
        <div>
            <div className={css(S.Title)}>{title}</div>
            {underline ? (
                <div className={css(S.HorizontalRule)} />
            ) : (
                <div className={css(S.Spacer)} />
            )}
        </div>
    );
};

export default SectionHeader;
