import { useStyles, Image, Flex, Caption } from '@archipro-design/aria';
import * as S from './PillarLinkGroup.style';
import PillarLink from '@modules/home/component/pillar-module/PillarLink';
import { Link } from '@remix-run/react';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { useAppData } from '~/modules/root';
import type { Directory } from '~/modules/directory/type';

export interface PillarLinkGroupProps {
    directory: Directory;
    directoryLink: string;
    image: string;
    pillarText?: string;
    lightMode?: boolean;
    isMobile: boolean;
}

const PillarLinkGroup = ({
    image,
    directory,
    directoryLink,
    pillarText,
    lightMode,
    isMobile,
}: PillarLinkGroupProps) => {
    const { css } = useStyles({ lightMode });

    const { megamenu } = useAppData();

    const categoryEdges = megamenu
        ? megamenu[directory].category.Categories?.edges
        : null;

    return (
        <div className={css(S.BaseStyle)}>
            <div className={css(S.ImageStyle)}>
                <Image
                    loading="lazy"
                    alt={pillarText ?? ''}
                    src={image}
                    width={isMobile ? 186 : 348}
                    height={isMobile ? 264.74 : 500}
                    sizes={getImagesSizes(isMobile ? 186 : 348)}
                    layout="fill"
                    objectFit="cover"
                />
                <Caption className={`${css(S.PillarText)} pillar-text`}>
                    <div
                        dangerouslySetInnerHTML={{ __html: pillarText ?? '' }}
                    />
                </Caption>
            </div>
            {isMobile ? (
                <Link
                    to={directoryLink}
                    prefetch="intent"
                    className={css(S.LinksOverlayStyle) + ' pillar-image'}
                >
                    {'  '}
                </Link>
            ) : (
                <div className={css(S.LinksOverlayStyle) + ' pillar-image'}>
                    <div className={css(S.ContentWrapStyle)}>
                        {categoryEdges && (
                            <Flex column={true} className={css(S.TopStyle)}>
                                <div>
                                    {categoryEdges.map(({ node }, k) => {
                                        return (
                                            <PillarLink
                                                key={k}
                                                link={node.Link}
                                                label={node.PrettyTitle}
                                            />
                                        );
                                    })}
                                </div>
                                <PillarLink
                                    label="View all"
                                    link={directoryLink}
                                />
                            </Flex>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PillarLinkGroup;
