import { useCallback, useEffect } from 'react';
import { useLatest } from 'ahooks';
import { fireAdEvent, useImpression } from '@archipro-website/tracker';
import { ARCHIPRO_IMPRESSION_TRACKER } from '@archipro-website/tracker';
import { useTracker } from '@archipro-website/tracker';
import type { HomeTileTrackerData } from '../type';
import { fireHomeTileEvent } from '../util/home-tile-tracker-helpers';
import type { AdvertCreative } from '@modules/adverts/type';
import { useLogger } from '@archipro-website/logger/client';

interface UseHomeTileEventHandlerResult {
    onClick: () => void;
}

const useHomeTileEventHandler = (
    trackerData: HomeTileTrackerData | undefined,
    ref: React.MutableRefObject<Element | null>,
    advertCreative: AdvertCreative | null | undefined = null
): UseHomeTileEventHandlerResult => {
    const adCreative = useLatest(advertCreative);
    const data = useLatest<HomeTileTrackerData | undefined>(trackerData);
    const tracking = useTracker();
    const logger = useLogger();

    useImpression({
        ref,
        disable: !data.current,
        onImpression: async (url: URL) => {
            if (!data.current) {
                return;
            }
            const urlString = url.toString();
            // TODO: HomepageTileImpression is out of date. Will resume it in BA-1460.
            // fireHomeTileEvent(
            //     'HomepageTileImpression',
            //     urlString,
            //     data.current,
            //     tracking
            // );
            if (data.current?.sectionType === 'products') {
                fireHomeTileEvent(
                    'HPProductTileImpression',
                    window.location.href,
                    data.current,
                    tracking,
                    logger
                );
            }

            if (adCreative.current) {
                fireAdEvent(
                    'Ad_Impression',
                    urlString,
                    adCreative.current,
                    tracking,
                    logger
                );
            }

            if (data.current.itemID) {
                tracking.log('Impression', {
                    url: url,
                    data: {
                        ID: data.current.itemID,
                        type: 'LibraryItem_Directory',
                        ProfessionalID: data.current.professionalID,
                    },
                    targetTracker: ARCHIPRO_IMPRESSION_TRACKER,
                });
            }
        },
    });

    const onServe = useCallback(() => {
        if (adCreative.current) {
            fireAdEvent(
                'Ad_Serve',
                window.location.href,
                adCreative.current,
                tracking,
                logger
            );
        }
        if (data.current?.sectionType === 'products') {
            fireHomeTileEvent(
                'HPProductTileServe',
                window.location.href,
                data.current,
                tracking,
                logger
            );
        }
    }, [data, logger, tracking, adCreative]);

    // Fire once for each instance of the hook
    useEffect(() => {
        if (adCreative && ref.current) {
            onServe();
        }
    }, [adCreative, ref, onServe]);

    const onClick = useCallback(() => {
        if (adCreative.current) {
            fireAdEvent(
                'Ad_CTAClick',
                window.location.href,
                adCreative.current,
                tracking,
                logger
            );
        }

        if (data.current) {
            // TODO: HomepageTileClick is out of date. Will resume it in BA-1460.
            // fireHomeTileEvent('HomepageTileClick', window.location.href, data.current, tracking);
            if (data.current.sectionType === 'products') {
                fireHomeTileEvent(
                    'HPTileClick',
                    window.location.href,
                    data.current,
                    tracking,
                    logger
                );
            }
        }
    }, [data, adCreative, tracking, logger]);

    return {
        onClick,
    };
};

export default useHomeTileEventHandler;
