import ProductsTiles from '@modules/home/component/products-module/ProductsTiles';
import type { HomeTileViewModel } from '@modules/home/type';
import SectionHeader from '@modules/home/component/common/SectionHeader';
import type { ProductHomeTileProps } from '@modules/home/util/product-home-tile-view-model.server';
import type { AdvertCreative } from '@modules/adverts/type';
import { useSaveToDesignBoard } from '@modules/design-board';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import CategoryNav from '@modules/home/component/common/CategoryNav';
import type { NavigationItem } from '@archipro-website/top-navigation';
import {
    Button,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ProductsModule.style';
import { Link } from '@remix-run/react';

interface ProductsModuleProps {
    isMobile: boolean;
    productTiles: HomeTileViewModel<ProductHomeTileProps>[];
    sectionTitle?: string;
    categoryNavItems?: NavigationItem[];
    ads: AdvertCreative[];
}

const ProductsModule = ({
    isMobile,
    productTiles,
    sectionTitle,
    categoryNavItems,
    ads,
}: ProductsModuleProps) => {
    const { favouriteItem } = useSaveToDesignBoard('ProductPin');
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    const navItems: NavigationItem[] = categoryNavItems
        ? [
              ...[
                  {
                      id: '-1',
                      name: 'Featured',
                      link: '/products/sort_featured',
                  },
              ],
              ...(categoryNavItems.slice(1, categoryNavItems.length) ?? []),
          ]
        : [];

    return (
        <div className={css(S.ProductModuleStyle)}>
            {sectionTitle && <SectionHeader title={sectionTitle} />}
            {navItems.length > 0 && (
                <div className={css(S.CategoryNavContainer)}>
                    <CategoryNav
                        items={navItems}
                        {...(!isMobile && {
                            groupCTA: {
                                title: `Browse products`,
                                link: `/products`,
                            },
                        })}
                    />
                </div>
            )}
            <ProductsTiles
                isMobile={isMobile}
                productTiles={productTiles}
                favouriteItem={favouriteItem}
                ads={ads}
            />
            {mobile && (
                <div className={css(S.ButtonWrapMobileStyle)}>
                    <Button
                        as={Link}
                        to={'/products'}
                        prefetch="intent"
                        transparent
                        outlined
                        size={16}
                        fluid
                        className={css(S.ButtonMobileStyle)}
                        variables={(siteVars) => ({
                            primaryOutlinedButtonBorderNormal: `1px solid ${siteVars?.colors.charcoal[100]}`,
                            primaryOutlinedButtonTextColorNormal:
                                siteVars?.colors.charcoal[250],
                        })}
                    >
                        Browse products
                    </Button>
                </div>
            )}
            {favouriteItem.target && (
                <SaveToDesignBoard {...favouriteItem} autoReposition={false} />
            )}
        </div>
    );
};

export default ProductsModule;
