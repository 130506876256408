import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    display: 'block',
    color: theme.siteVariables.colors.white['000'],
    '&:hover': {
        color: theme.siteVariables.colors.white['100'],
    },
});

export const WrapStyle: StyleRule = () => ({
    '&:hover .pillar-arrow': {
        transition: '0.3s linear',
        marginRight: 0,
        opacity: 1,
    },
});

export const ArrowStyle: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.white['000'],
    transition: '0.3s linear',
    opacity: 0,
    marginLeft: pxToRem(8),
    '> span': {
        width: pxToRem(16),
        height: pxToRem(16),
    },
});

export const LabelStyle: StyleRule = () => ({
    maxWidth: pxToRem(250),
});
