import type { Tracker, TrackingEvent } from '@archipro-website/tracker';
import { useTracker, useImpression } from '@archipro-website/tracker';
import { useLatest } from 'ahooks';
import { useCallback, useEffect } from 'react';

type BannerType = 'FullWidth' | 'HalfWidth';
interface HomePageBannerEventProps {
    type: BannerType;
    itemID: number; // item id that the banner is linked to
    position: number; // position of the banner in the list. start from 1.
}

interface UseHomePageBannerEventHandlerOut {
    onClick: () => void;
}

const trackHomePageBannerEvent = (
    tracker: Tracker,
    event: TrackingEvent,
    props: HomePageBannerEventProps
) => {
    const { itemID: productID, position } = props;
    tracker.log(event, {
        url: new URL(window.location.href),
        targetTracker: 'archiproTrackerV2',
        data: {
            // format: <item_id>,<position>
            extraData: `${productID},${position}`,
        },
    });
};

export const useHomePageBannerEventHandler = (
    eventProps: HomePageBannerEventProps | null,
    ref: React.MutableRefObject<Element | null>
): UseHomePageBannerEventHandlerOut => {
    const eventData = useLatest(eventProps);
    const tracker = useTracker();

    useImpression({
        ref,
        disable: !eventData.current,
        onImpression: async () => {
            if (eventData.current) {
                trackHomePageBannerEvent(
                    tracker,
                    eventData.current.type === 'FullWidth'
                        ? 'HPBannerImpression'
                        : 'HPHalfWidthImpression',
                    eventData.current
                );
            }
        },
    });

    useEffect(() => {
        if (eventData.current) {
            trackHomePageBannerEvent(
                tracker,
                eventData.current.type === 'FullWidth'
                    ? 'HPBannerServe'
                    : 'HPHalfWidthServe',
                eventData.current
            );
        }
    }, [tracker, eventData]);

    const onClick = useCallback(() => {
        if (eventData.current) {
            trackHomePageBannerEvent(
                tracker,
                eventData.current.type === 'FullWidth'
                    ? 'HPCtaClick'
                    : 'HPHalfWidthClick',
                eventData.current
            );
        }
    }, [tracker, eventData]);

    return {
        onClick,
    };
};
