import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Card: StyleRule = ({ theme }) => ({
    position: 'relative',

    [theme.screen.tablet]: {
        width: pxToRem(150),
        height: pxToRem(150),
    },
});

export const ImageWrap: StyleRule = () => ({
    width: '100%',
    height: '100%',

    [`& img`]: {
        mixBlendMode: 'multiply',
    },
});
