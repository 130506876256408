import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Container: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        maxWidth: pxToRem(1264),
        width: '100%',
        margin: `0 auto`,
    },
});

export const GridWrapper: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        gridGap: pxToRem(8),
    },
});
