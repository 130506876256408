import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { BANNER_BOTTOM_OVERLAY_OFFSET } from '@modules/home/const';

export const BaseStyle: StyleRule = ({ theme }) => ({
    display: 'block',
    position: 'relative',
    textDecoration: 'none',
    backgroundColor: theme.siteVariables.bodyBackground,
    lineHeight: 1,
});

export const ImageOverlayContainer: StyleRule = ({ theme }) => ({
    position: 'relative',

    [theme.screen.tablet]: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
});

export const OverlayContainerStyle: StyleRule = ({ theme }) => ({
    position: 'relative',

    [theme.screen.tablet]: {
        position: 'sticky',
        bottom: pxToRem(BANNER_BOTTOM_OVERLAY_OFFSET),
        height: 0,
    },
});

export const HeaderBannerControls: StyleRule = ({ theme }) => ({
    // custom pagination
    position: 'absolute',
    bottom: pxToRem(19),
    right: pxToRem(12),
    zIndex: theme.siteVariables.zIndexes.foreground,

    [theme.screen.tablet]: {
        bottom: pxToRem(53),
        right: pxToRem(58),
    },
});
export const HeaderBannerControlContainer: StyleRule = () => ({
    display: 'flex',
    alignContent: 'center',
});
export const PaddleBase: StyleRule = () => ({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const FallbackImageContainer: StyleRule = ({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.siteVariables.colors.white['200A'],
    marginBottom: pxToRem(2),
    [`& img`]: {
        mixBlendMode: 'multiply',
    },
});
