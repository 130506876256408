import {
    Caption,
    Flex,
    Grid,
    Label,
    pxToRem,
    useStyles,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import * as S from './PillarsGroup.style';
import type { PillarProps } from '@modules/home/component/pillar-module/Pillar';
import Pillar from '@modules/home/component/pillar-module/Pillar';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import { useCountryContext } from '@archipro-website/localisation';
import SectionHeader from '../common/SectionHeader';

const ABOUT_ARCHIPRO = {
    AU: `Australia's ultimate marketplace for Architecture, Building and Design`,
    NZ: `Find project inspiration, connect with trusted professionals and search products for your building project.`,
};

interface PillarsGroupProps {
    isMobile: boolean;
}

const TITLE_PILLAR = `Here’s what you can do on ArchiPro`;

const PillarsGroup = ({ isMobile }: PillarsGroupProps) => {
    const { css } = useStyles();
    const config = useConfigContext();
    const { byCountry } = useCountryContext();
    const { desktop } = useAppDisplayModeContext();

    const pillarData: Omit<PillarProps, 'isMobile'>[] = [
        {
            pillarText: `Shop and specify <br>quality products`,
            pillarLink: {
                image: `${config.assetsBaseUrl}/assets/website/images/homepage/pillar-products.jpg`,
                directory: 'product',
                directoryLink: '/products',
            },
        },
        ...(!isMobile
            ? [
                  {
                      pillarText: TITLE_PILLAR,
                      pillarContent: byCountry(ABOUT_ARCHIPRO),
                  },
              ]
            : []),
        {
            pillarText: 'Find trusted <br>professionals',
            pillarLink: {
                image: `${config.assetsBaseUrl}/assets/website/images/homepage/pillar-professionals.jpg`,
                directory: 'professional',
                directoryLink: '/professionals',
            },
        },
        {
            pillarText: 'Discover <br>inspiring projects',
            pillarLink: {
                image: `${config.assetsBaseUrl}/assets/website/images/homepage/pillar_projects.jpg`,
                directory: 'project',
                directoryLink: '/projects',
            },
        },
        {
            pillarText: `Design news, trends ${
                desktop ? '<br>' : ''
            }and insights`,
            pillarLink: {
                image: `${config.assetsBaseUrl}/assets/website/images/homepage/pillar-articles.jpg`,
                directory: 'article',
                directoryLink: '/articles',
            },
        },
    ];

    return (
        <>
            {isMobile && <SectionHeader title={TITLE_PILLAR} shortTitle />}
            <Grid
                className={css(S.BaseStyle)}
                columns={isMobile ? 2 : `repeat(5, ${pxToRem(348)})`}
            >
                {pillarData.map((data, k) => {
                    if (!data.pillarLink)
                        return (
                            <Flex
                                className={css(S.PillarContentCard)}
                                vAlign={'center'}
                                column={true}
                                key={k}
                            >
                                <Label>{data.pillarText}</Label>
                                <Caption variant="03">
                                    {data.pillarContent}
                                </Caption>
                            </Flex>
                        );

                    return <Pillar key={k} {...data} isMobile={isMobile} />;
                })}
            </Grid>
        </>
    );
};

export default PillarsGroup;
