import type {
    ShorthandCollection,
    ImageProps,
    LoadingValue,
} from '@archipro-design/aria';

import type { Directory } from '~/modules/directory/type';
import { getImagesSizes } from '~/utils/image-sizes-utils';

export const injectLoadingToImagePropsCollection = (
    images: string[],
    loading: LoadingValue,
    isMobile?: boolean,
    moduleType?: Directory,
    title?: string
): ShorthandCollection<ImageProps> => {
    const imagesOut: ShorthandCollection<ImageProps> = [];
    const imageSizes = getImageSizes(isMobile, moduleType);

    images.forEach((img) => {
        imagesOut.push({
            src: img || '',
            width: 0, // The 0 is needed for prop compatibility, this is overridden
            height: 0,
            sizes: imageSizes,
            alt: title || '',
            loading: loading,
        });
    });

    return imagesOut;
};

const getImageSizes = (isMobile?: boolean, moduleType?: Directory): string => {
    const isProduct = moduleType === 'product';
    const mobileWidth = isProduct ? '50vw' : '100vw';

    if (isMobile) return mobileWidth;

    switch (moduleType) {
        case 'product':
            return getImagesSizes(281);
        case 'professional':
            return getImagesSizes(881);
        case 'article':
            return getImagesSizes(780);
        case 'project':
            return getImagesSizes(1820);
        default:
            return getImagesSizes(281);
    }
};
