import {
    Caption,
    Image,
    useStyles,
    Flex,
    Subheader,
    useAppDisplayModeContext,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './MediaBlock.style';
import { ArrowRightLine } from '@archipro-design/icons';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import type { AdvertCreative } from '@modules/adverts/type';
import type { AdEventHandlerOnClick } from '@archipro-website/tracker';
import { useTracker } from '@archipro-website/tracker';
import { createLinkedComponentParams } from '~/utils/urlHelper';

interface MediaBlockProps {
    id: number;
    image: string;
    title: string;
    subTitle: string;
    linkLabel: string;
    linkHref: string;
    onClick: AdEventHandlerOnClick;
    advertCreative: AdvertCreative | null;
}

export type MediaBlockNoClickProps = Omit<MediaBlockProps, 'onClick'>;

const MediaBlock = ({
    image,
    title,
    subTitle,
    linkLabel,
    linkHref,
    onClick,
    advertCreative,
}: MediaBlockProps) => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    const tracker = useTracker();

    return (
        <div className={css(S.Card)}>
            <div className={css(S.ImageWrap)}>
                <Image
                    src={image}
                    width={709}
                    alt={title}
                    height={932}
                    layout={'fill'}
                    objectFit={'cover'}
                    sizes={getImagesSizes(709)}
                    overlay={{
                        ...createLinkedComponentParams(
                            tracker,
                            'Ad_ImageClick',
                            linkHref,
                            {
                                libraryItemID: advertCreative?.parent.id,
                                professionalID: advertCreative?.professional.id,
                            },
                            null,
                            onClick
                        ),
                        icon: <ArrowRightLine />,
                        title: linkLabel,
                        alwaysVisible: true,
                        variables: {
                            fadeSpeed: '0.15s',
                        },
                    }}
                />
            </div>
            <Flex className={css(S.Overlay)} column>
                {subTitle && (
                    <Caption
                        variant="03"
                        className={css(S.Subtitle)}
                        variables={{
                            fontSize: !desktop ? pxToRem(14) : pxToRem(16),
                        }}
                    >
                        {subTitle}
                    </Caption>
                )}
                {title && (
                    <Subheader
                        variant="03"
                        className={css(S.Title)}
                        variables={{
                            fontSize: !desktop ? pxToRem(23) : pxToRem(30),
                        }}
                    >
                        {title}
                    </Subheader>
                )}
                {linkLabel && (
                    <Caption
                        variant="03"
                        className={css(S.ViewLink)}
                        variables={{
                            fontSize: !desktop ? pxToRem(14) : pxToRem(16),
                        }}
                    >
                        {linkLabel}
                    </Caption>
                )}
            </Flex>
        </div>
    );
};

export default MediaBlock;
