/*
0500 to 1159 Good morning <first name>

1200 to 1729 Good afternoon <first name>

1730 to 1929 Good evening <first name>

1930 to 0459 Hello <first name>
 */

import type { LocalisedDateFn } from '@archipro-website/localisation';

export const greeting = (dateFn: LocalisedDateFn): string => {
    let now = dateFn(new Date());
    let minutesOfTheDay = now.minute() + now.hour() * 60;
    if (minutesOfTheDay >= 300 && minutesOfTheDay < 720) {
        return 'Good morning,';
    }
    if (minutesOfTheDay >= 720 && minutesOfTheDay < 1050) {
        return 'Good afternoon,';
    }
    if (minutesOfTheDay >= 1050 && minutesOfTheDay < 1170) {
        return 'Good evening,';
    }
    return 'Hello,';
};
