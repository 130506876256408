import type { StyleRule } from '@archipro-design/aria';
import {
    buttonClassName,
    captionClassName,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';
import { hex2rgba } from '~/utils';
import { headerClassName, labelClassName } from '@fluentui/react-northstar';
import { svgIconClassName } from '@archipro-design/icons';

export const HeaderRoot: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(70),
    gridGap: pxToRem(30),

    [theme.screen.tablet]: {
        marginTop: pxToRem(15),
        gridGap: pxToRem(76),
        marginBottom: 0,
    },
});

export const HeaderWelcomeWrap: StyleRule = ({ theme }) => ({
    textAlign: 'center',
    [theme.screen.tablet]: {
        minHeight: pxToRem(371),
        textAlign: 'left',
    },
});

export const HeaderSignupPrompt: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        marginBottom: pxToRem(12),
        [`&.${captionClassName}`]: {
            color: hex2rgba(theme.siteVariables.colors.primitive.black, 0.6),
        },
    },
});

export const WelcomeHeader: StyleRule = ({ theme }) => ({
    [`&.${headerClassName}`]: {
        whiteSpace: 'pre-wrap',
        fontSize: pxToRem(20),
        paddingRight: 0,
        margin: pxArrayToRem([5, 21, 0]),
        textAlign: 'center',
        ...theme.siteVariables.textStyles.Heading.Roman55,
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        lineHeight: theme.siteVariables.lineHeightDefault,

        [theme.screen.tablet]: {
            margin: pxArrayToRem([102, 0, 0]),
            fontSize: pxToRem(30),
            paddingRight: '1ch',
            textAlign: 'left',
        },
    },
});

export const WelcomeButtonSignIn: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(28),
    marginBottom: pxToRem(40),
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
    },
    [`&.${buttonClassName} .${svgIconClassName}`]: {
        width: pxToRem(24),
        height: pxToRem(24),
    },
});

export const GreetingContainer: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([10, 18, 8]),
    textAlign: 'left',
    [theme.screen.tablet]: {
        padding: 0,
    },
});

export const GreetingPrompt: StyleRule = ({ theme }) => ({
    lineHeight: theme.siteVariables.lineHeightDefault,
    ...theme.siteVariables.textStyles.Heading.Roman55,
    fontSize: pxToRem(30),

    [theme.screen.tablet]: {
        marginBottom: pxToRem(8),
        marginTop: pxToRem(85),
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: 'unset',
    },
});

export const FirstName: StyleRule = ({ theme }) => ({
    [`&.${captionClassName}`]: {
        lineHeight: theme.siteVariables.lineHeightDefault,
        ...theme.siteVariables.textStyles.Heading.Roman55,
        fontSize: pxToRem(30),
    },

    [theme.screen.tablet]: {
        [`&.${captionClassName}`]: {
            fontSize: pxToRem(44),
        },
    },
});
