import {
    Grid,
    Flex,
    pxToRem,
    useStyles,
    Caption,
    Header,
    useAppDisplayModeContext,
    Button,
    pxArrayToRem,
} from '@archipro-design/aria';
import HeaderBanner from '@modules/home/component/header-module/HeaderBanner';
import type { SaveToDesignBoardProps } from '@modules/design-board/component/SaveToDesignBoard';
import type { ResponsiveImage } from '~/modules/images/type';
import HeaderMenu from '@modules/home/component/header-module/HeaderMenu';
import * as S from './HeaderModule.style';
import { greeting } from '@modules/date-time/util/greeting';
import type { User } from '~/server/types';
import { isUserLoggedIn } from '@modules/user';
import {
    useCountryContext,
    useLocalisation,
} from '@archipro-website/localisation';
import type { AdvertCreative } from '@modules/adverts/type';
import { CustomArrowRight } from '@archipro-design/icons';
import HeaderCategorynav from './HeaderCategoryNav';
import { hasFeature } from '~/modules/root';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import { useState } from 'react';
import { useTracker } from '@archipro-website/tracker';

export interface BannerTileProps {
    id: number;
    pinType: SaveToDesignBoardProps['itemType'];
    title: string;
    subTitle?: string;
    image: ResponsiveImage;
    ctaLink: string;
    isExternal: boolean;
    ctaLabel: string;
    category: string;
    advertCreative: AdvertCreative | null;
}

interface HeaderModuleProps {
    isMobile: boolean;
    bannerTiles: BannerTileProps[];
    onSignupClick: () => void;
    user: User;
}

const INTRO_CONTENT = {
    AU: `Australia's ultimate marketplace for Architecture, Building and Design`,
    NZ: `Find project inspiration, connect with trusted professionals and search products for your building project.`,
};

const MOBILE_NZ_CONTENT = `Find project inspiration, connect with trusted professionals and search products for your\nbuilding project.`;

const useByDevice = () => {
    const { desktop, mobile } = useAppDisplayModeContext();

    return ({
        desktopContent,
        mobileContent,
    }: {
        desktopContent: string;
        mobileContent: string;
    }) => {
        switch (true) {
            case desktop:
                return desktopContent;
            case mobile:
                return mobileContent;
            default:
                return desktopContent;
        }
    };
};

const HeaderModule = ({
    isMobile,
    bannerTiles,
    onSignupClick,
    user,
}: HeaderModuleProps) => {
    const { css } = useStyles();
    const { byCountry } = useCountryContext();
    const { localiseDate } = useLocalisation();
    const { desktop } = useAppDisplayModeContext();
    const tracker = useTracker();

    const loggedIn = isUserLoggedIn(user);
    const shopNavEnabled = hasFeature(user.FeatureFlags, 'shopnav');
    const [showSignUp, setShowSignUp] = useState(false);

    const onLoginClick = () => {
        setShowSignUp(true);
        tracker.log('accountLogin', {
            url: new URL(window.location.href),
            data: {
                step: 1,
            },
        });
    };

    const byDevice = useByDevice();
    const content = byDevice({
        desktopContent: byCountry(INTRO_CONTENT),
        mobileContent: byCountry({
            NZ: MOBILE_NZ_CONTENT,
            AU: INTRO_CONTENT.AU,
        }),
    });

    return (
        <Grid
            className={css(S.HeaderRoot)}
            columns={isMobile ? `100%` : `${pxToRem(392)} 1fr`}
        >
            {!desktop && <HeaderCategorynav shopNavEnabled={shopNavEnabled} />}
            <Flex column={true}>
                <div className={css(S.HeaderWelcomeWrap)}>
                    {!loggedIn && (
                        <>
                            <Header
                                className={css(S.WelcomeHeader)}
                                variables={(siteVars) => ({
                                    ...(!desktop && {
                                        color: siteVars?.colors.primitive.black,
                                    }),
                                })}
                            >
                                {content}
                            </Header>
                            {!desktop && (
                                <Button
                                    size={16}
                                    iconAfter={<CustomArrowRight />}
                                    className={css(S.WelcomeButtonSignIn)}
                                    variables={(siteVars) => ({
                                        buttonSize16IconXMargin: pxToRem(8),
                                        iconPadding: 0,
                                        buttonPadding: pxArrayToRem([
                                            16, 21.94,
                                        ]),
                                        primaryButtonBackgroundColorNormal:
                                            siteVars?.colors.primitive.black,
                                    })}
                                    onClick={onLoginClick}
                                >
                                    SIGN UP / LOG IN
                                </Button>
                            )}
                            <LoginSignUpModal
                                login={{
                                    open: showSignUp,
                                    onCancel: () => setShowSignUp(false),
                                    onConfirm: () => setShowSignUp(false),
                                    onAuthSuccess: () => setShowSignUp(false),
                                    flow: null,
                                    authSource: 'headerModule',
                                }}
                                forgotPassword={{
                                    open: false,
                                    onCancel: () => setShowSignUp(false),
                                }}
                            />
                        </>
                    )}
                    {loggedIn && (
                        <div className={css(S.GreetingContainer)}>
                            <Caption
                                variant="03"
                                className={css(S.GreetingPrompt)}
                                variables={(siteVars) => ({
                                    ...(!desktop && {
                                        color: siteVars?.colors.primitive.black,
                                    }),
                                })}
                            >
                                {greeting(localiseDate)}
                            </Caption>
                            <Caption
                                className={css(S.FirstName)}
                                variables={(siteVars) => ({
                                    ...(!desktop && {
                                        color: siteVars?.colors.primitive.black,
                                    }),
                                })}
                            >
                                {user.FirstName}
                            </Caption>
                        </div>
                    )}
                </div>
                {!isMobile && (
                    <>
                        {!loggedIn && (
                            <Caption
                                className={css(S.HeaderSignupPrompt)}
                                variant="04"
                            >{`Sign up to unlock these features:`}</Caption>
                        )}
                        <HeaderMenu loggedIn={loggedIn} />
                    </>
                )}
            </Flex>
            <HeaderBanner
                onSignupClick={onSignupClick}
                isMobile={isMobile}
                loggedIn={loggedIn}
                images={bannerTiles.map((bannerTile, key) => {
                    const src = isMobile
                        ? bannerTile.image.Mobile
                        : bannerTile.image.Desktop;
                    return {
                        src: src ?? '',
                        key: String(key),
                    };
                })}
                overlays={bannerTiles.map((bannerTile) => {
                    return {
                        id: bannerTile.id,
                        pinType: bannerTile.pinType,
                        title: bannerTile.title,
                        subTitle: bannerTile.subTitle,
                        ctaLink: bannerTile.ctaLink,
                        ctaLabel: bannerTile.ctaLabel,
                        isExternal: bannerTile.isExternal,
                        category: bannerTile.category,
                        loggedIn: loggedIn,
                        advertCreative: bannerTile.advertCreative,
                    };
                })}
                delay={4000}
            />
        </Grid>
    );
};

export default HeaderModule;
