import {
    Grid,
    HotButtons,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ProductsTiles.style';
import type { HomeSectionType, HomeTileViewModel } from '@modules/home/type';
import type { ProductHomeTileProps } from '@modules/home/util/product-home-tile-view-model.server';
import { getHomeSectionColumn } from '../../util/home-page-section-helpers';
import type { FavouriteItem } from '~/modules/design-board/type/design-board-types';
import HomeProductTile from '@modules/home/component/products-module/HomeProductTile';
import {
    adCreativeToProductHomeTile,
    adHomeTileTrackerDataPatch,
} from '@modules/home/util/ads-helpers';
import type { AdvertCreative } from '@modules/adverts/type';
import { getTrackerData } from '../../util/home-tile-tracker-helpers';
import { useTracker } from '@archipro-website/tracker';
import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { useToggleHomePin } from '../../hook/use-toggle-home-pin';
import { useAppData } from '~/modules/root';
import { getHotButtons } from '~/modules/directory/util/get-hot-buttons';
import { canHomePin } from '../../util/home-pin-helpers';
import MessageModal from '../common/MessageModal';

interface ProductsTilesPros {
    isMobile: boolean;
    productTiles: HomeTileViewModel<ProductHomeTileProps>[];
    favouriteItem: FavouriteItem;
    ads: AdvertCreative[];
}

const ProductsTiles = ({
    productTiles,
    isMobile,
    favouriteItem,
    ads,
}: ProductsTilesPros) => {
    const { css } = useStyles();
    const tracker = useTracker();
    const navigate = useNavigate();

    const navigateClick = (url: string) => {
        navigate(url);
    };

    const sectionType: HomeSectionType = 'products';

    const columnPerRow = getHomeSectionColumn(sectionType, !isMobile);

    const appDisplayMode = useAppDisplayModeContext();
    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editProfile;

    const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            setPinErrorMessage(error.Message);
        }
    });

    const tiles = productTiles.map(
        ({ link, id, tile, professionalID }, index) => {
            const ad = ads[index] ? ads[index] : null;
            const trackerData = getTrackerData({
                professionalID: professionalID || 0,
                itemID: id,
                sectionType: 'products',
                position: index,
                columnPerRow,
            });

            // If ad found replace tile with ad
            if (ad) {
                return (
                    <HomeProductTile
                        key={ad.id}
                        tracker={tracker}
                        link={ad.cta.href}
                        tile={adCreativeToProductHomeTile(ad)}
                        isMobile={isMobile}
                        favouriteItem={favouriteItem}
                        onProfessionalClick={navigateClick}
                        ad={ad}
                        id={ad.parent.id || 0}
                        trackerData={adHomeTileTrackerDataPatch(
                            trackerData,
                            ad,
                            'products'
                        )}
                    />
                );
            } else {
                const hotButtons = getHotButtons({
                    item: tile,
                    itemType: 'product',
                    appDisplayMode,
                    canEditAdmin,
                    canEdit: canEditAdmin,
                    pinned: !!tile.homePinned,
                    onPinClick: canHomePin(rootGrants)
                        ? () => {
                              toggleHomePin(id, !tile.homePinned);
                          }
                        : undefined,
                });
                return (
                    <HomeProductTile
                        key={link}
                        tracker={tracker}
                        id={id}
                        tile={tile}
                        isMobile={isMobile}
                        link={link}
                        favouriteItem={favouriteItem}
                        onProfessionalClick={navigateClick}
                        trackerData={trackerData}
                        topActions={
                            hotButtons ? (
                                <HotButtons items={hotButtons} />
                            ) : undefined
                        }
                    />
                );
            }
        }
    );

    return (
        <>
            <Grid className={css(S.GridWrapper)} columns={columnPerRow}>
                {tiles}
            </Grid>
            {pinErrorMessage && (
                <MessageModal
                    title={'Warning'}
                    content={pinErrorMessage}
                    open={true}
                    onConfirm={() => setPinErrorMessage(null)}
                />
            )}
        </>
    );
};

export default ProductsTiles;
