import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: pxArrayToRem([0, 11]),
});

export const MeterStyle: StyleRule = ({ theme, delay }) => ({
    backgroundColor: '#D9D9D9',
    width: pxToRem(40),
    height: pxToRem(5),
    marginRight: pxToRem(6),

    '&:last-child': {
        marginRight: 0,
    },

    '&.animate > div': {
        transition: `width ${delay}ms linear`,
        animationFillMode: 'forwards',
    },
    '&.fill': {
        backgroundColor: theme.siteVariables.colors.primitive.black,
    },

    [theme.screen.tablet]: {
        marginRight: pxToRem(11),
        width: pxToRem(43),
        height: pxToRem(5),
    },
});

export const BarStyle: StyleRule = ({ theme }) => ({
    backgroundColor: theme.siteVariables.colors.primitive.black,
    height: pxToRem(5),
});
