import type { CommonModule } from '@modules/elemental-page/type';
import ElementalModule from '@modules/elemental-page/component/elemental-module/ElementalModule';
import { ModulePageThemeEnum } from '../../../../../generated/graphql';
import GuestTopNav from '@modules/home/component/guest-home/GuestTopNav';
import { useStyles } from '@archipro-design/aria';
import * as S from './GuestHome.style';

interface GuestHomeProps {
    modules: CommonModule[];
}

const GuestHome = ({ modules }: GuestHomeProps) => {
    const { css } = useStyles();
    return (
        <div className={css(S.GuestHomeContainer)}>
            <GuestTopNav />
            {modules?.map((module: CommonModule) => {
                return (
                    <ElementalModule
                        item={module}
                        pageTheme={ModulePageThemeEnum.Home}
                        key={module.id}
                    />
                );
            })}
        </div>
    );
};

export default GuestHome;
