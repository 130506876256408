import type { SaveToDesignBoardProps } from '@modules/design-board/component/SaveToDesignBoard';
import type { AdvertCreative } from '@modules/adverts/type';
import { useRef } from 'react';
import HeaderOverlay from '@modules/home/component/header-module/HeaderOverlay';
import { useAdEventHandler } from '@archipro-website/tracker';

interface HeaderOverlayProps {
    title: string;
    ctaLink: string;
    ctaLabel: string;
    pinType: SaveToDesignBoardProps['itemType'];
    id: number;
    category: string;
    loggedIn: boolean;
    advertCreative: AdvertCreative;
}

const HeaderOverlayAdWrapper = ({
    title,
    ctaLink,
    ctaLabel,
    pinType,
    id,
    category,
    loggedIn,
    advertCreative,
}: HeaderOverlayProps) => {
    const bannerRef = useRef(null);
    const { onClick } = useAdEventHandler(advertCreative, bannerRef);

    return (
        <div ref={bannerRef}>
            <HeaderOverlay
                title={title}
                ctaLink={ctaLink}
                ctaLabel={ctaLabel}
                pinType={pinType}
                id={id}
                category={category}
                loggedIn={loggedIn}
                advertCreative={advertCreative}
                onClick={(adEventType) => {
                    onClick(adEventType);
                }}
            />
        </div>
    );
};

export default HeaderOverlayAdWrapper;
