import type {
    HomeTileViewModel,
    ProfessionalHomeTileProps,
} from '@modules/home/type';
import { useSaveToDesignBoard } from '@modules/design-board';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import CategoryNav from '@modules/home/component/common/CategoryNav';
import { useAppData } from '@modules/root';
import type { NavigationItem } from '@archipro-website/top-navigation';
import { HotButtons, useAppDisplayModeContext } from '@archipro-design/aria';
import SectionTitleSlider from '../common/SectionTitleSlider';
import HomeProfessionalTile from './HomeProfessionalTile';
import HomeSliderTiles from '../common/HomeSliderTiles';
import { getTrackerData } from '../../util/home-tile-tracker-helpers';
import { useTracker } from '@archipro-website/tracker';
import { useState } from 'react';
import { useToggleHomePin } from '../../hook/use-toggle-home-pin';
import { getHotButtons } from '~/modules/directory/util/get-hot-buttons';
import { canHomePin } from '../../util/home-pin-helpers';
import MessageModal from '../common/MessageModal';

interface ProfessionalsModuleProps {
    professionalTiles: HomeTileViewModel<ProfessionalHomeTileProps>[];
}

const ProfessionalsModule = ({
    professionalTiles,
}: ProfessionalsModuleProps) => {
    const { favouriteItem } = useSaveToDesignBoard('ProfessionalPin');
    const { megamenu } = useAppData();
    const tracker = useTracker();
    const appDisplayMode = useAppDisplayModeContext();
    const { desktop } = appDisplayMode;

    const categoryNavItems: NavigationItem[] = [
        ...[
            {
                id: '-1',
                name: 'Featured',
                link: '/professionals/sort_featured',
            },
        ],
        ...(megamenu?.professional.subNavItems.slice(
            1,
            megamenu.professional.subNavItems.length
        ) ?? []),
    ];

    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editProfile;

    const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            setPinErrorMessage(error.Message);
        }
    });

    const tiles = professionalTiles.map(({ link, id, tile }, index) => {
        const trackerData = getTrackerData({
            professionalID: id,
            itemID: id,
            sectionType: 'professionals',
            position: index,
            columnPerRow: professionalTiles.length, // slider, all elements in one row
        });
        const hotButtons = getHotButtons({
            item: tile,
            itemType: 'professional',
            appDisplayMode,
            canEditAdmin,
            canEdit: canEditAdmin,
            pinned: !!tile.homePinned,
            onPinClick: canHomePin(rootGrants)
                ? () => {
                      toggleHomePin(id, !tile.homePinned);
                  }
                : undefined,
        });
        return (
            <HomeProfessionalTile
                key={link}
                tracker={tracker}
                id={id}
                tile={tile}
                isMobile={!desktop}
                link={link}
                favouriteItem={favouriteItem}
                trackerData={trackerData}
                topActions={
                    hotButtons ? <HotButtons items={hotButtons} /> : undefined
                }
            />
        );
    });

    return (
        <>
            <SectionTitleSlider
                title={`Create your space with confidence: Find trusted professionals near you`}
                buttonLink={'/professionals'}
                buttonTitle={'Browse professionals'}
                categorySlot={
                    desktop && (
                        <CategoryNav
                            items={categoryNavItems}
                            {...(desktop && {
                                groupCTA: {
                                    title: `Browse professionals`,
                                    link: `/professionals`,
                                },
                            })}
                        />
                    )
                }
            >
                <HomeSliderTiles tiles={tiles} />
                {favouriteItem.target && (
                    <SaveToDesignBoard
                        {...favouriteItem}
                        autoReposition={false}
                    />
                )}
            </SectionTitleSlider>
            {pinErrorMessage && (
                <MessageModal
                    title={'Warning'}
                    content={pinErrorMessage}
                    open={true}
                    onConfirm={() => setPinErrorMessage(null)}
                />
            )}
        </>
    );
};

export default ProfessionalsModule;
