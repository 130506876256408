import type { HomeSectionType } from '@modules/home/type';
import type { AdvertCreative } from '@modules/adverts/type';
import type { ProductHomeTileProps } from '@modules/home/util/product-home-tile-view-model.server';
import type { HomeTileTrackerData } from '@modules/home/type';
import type { AdvertSections } from '@modules/adverts/type';
import type { PinItemType } from '@modules/design-board/type';
import type { PriceDisplayProps } from '@modules/tile/util/get-product-tile-price-display-props';
import getProductPriceDisplayProps from '@modules/tile/util/get-product-tile-price-display-props';

export const calculateAdsPattern = (pattern: number[], adCount: number) => {
    return pattern.map((adToUse) => {
        return adToUse < adCount ? adToUse : -1;
    });
};

export const adSectionToPinType = (
    section: AdvertSections
): PinItemType | null => {
    switch (section) {
        case 'Product':
            return 'ProductPin';
        case 'Professional':
            return 'ProfessionalPin';
        case 'Article':
            return 'ArticlePin';
        case 'Project':
            return 'ProjectPin';
        default:
            return null;
    }
};

export const adHomeTileTrackerDataPatch = (
    trackerData: HomeTileTrackerData | undefined,
    ad: AdvertCreative,
    sectionType: HomeSectionType
): HomeTileTrackerData | undefined => {
    if (trackerData === undefined) {
        return undefined;
    }

    return {
        professionalID: ad.professional.id || 0,
        row: trackerData.row,
        column: trackerData.column,
        columnPerRow: trackerData.columnPerRow,
        position: trackerData.position,
        sectionOrder: trackerData.sectionOrder,
        itemID: ad.parent.id || 0,
        sectionType,
    };
};

export const adCreativeToProductHomeTile = (
    ad: AdvertCreative
): ProductHomeTileProps => {
    const { variants, price, pricePrefix } =
        adCreativeProductDetailToTileProps(ad);

    return {
        isMultiplied: false,
        image: ad.carousel[0] ? [ad.carousel[0].image.mobile2x] : [],
        productName: ad.title,
        productLink: ad.cta.href,
        professionalName: ad.professional.title
            ? `By ${ad.professional.title}`
            : '',
        professionalLink: ad.professional.href,
        variants: variants || [],
        price: price,
        pricePrefix: pricePrefix || undefined,
        size: 'medium',
        canPurchase: ad.parent.productDetail?.canPurchase || false,
        colorsCount: ad.parent.productDetail.colorsCount,
    };
};

const adCreativeProductDetailToTileProps = (ad: AdvertCreative) => {
    let variants: ProductHomeTileProps['variants'] = [];
    let price = '';
    let pricePrefix: PriceDisplayProps['pricePrefix'] = '';

    if (ad.parent.productDetail) {
        const productPriceDisplayProps = getProductPriceDisplayProps(
            ad.parent.productDetail.pricingDisplay
        );

        price = productPriceDisplayProps.price || '';
        pricePrefix = productPriceDisplayProps.pricePrefix || '';

        if (ad.parent.productDetail.colors) {
            variants = ad.parent.productDetail.colors.map((color) => ({
                id: color.id,
                label: color.label,
                code: color.code,
                image: color.desktop,
            }));
        }
    }

    return {
        variants,
        price,
        pricePrefix,
    };
};
