import { Image, useStyles } from '@archipro-design/aria';
import type { ImageProps } from '@archipro-design/aria';
import * as S from './HeaderImage.style';
import { Link } from '@remix-run/react';
import type { AdvertCreative } from '~/modules/adverts/type';
import { useAdEventHandler } from '@archipro-website/tracker';
import { useRef } from 'react';

interface HeaderImageProps {
    image: ImageProps;
    ctaLink?: string;
    isExternal?: boolean;
    advertCreative?: AdvertCreative | null;
}

const HeaderImage = ({
    image,
    ctaLink,
    isExternal,
    advertCreative = null,
}: HeaderImageProps) => {
    const { css } = useStyles();
    const ref = useRef(null);
    // we have fired the serve and impression events in HeaderOverlays. So we disable them here.
    const { onClick } = useAdEventHandler(advertCreative, ref, {
        disableServeEvent: true,
        disableImpressionEvent: true,
    });

    return (
        <div className={css(S.BaseStyle)}>
            <div ref={ref} className={css(S.ImageWrapStyle)}>
                {ctaLink ? (
                    isExternal ? (
                        <a
                            href={ctaLink}
                            onClick={() => {
                                onClick('Ad_ImageClick');
                            }}
                        >
                            <Image {...image} />
                        </a>
                    ) : (
                        <Link
                            to={ctaLink}
                            onClick={() => {
                                onClick('Ad_ImageClick');
                            }}
                        >
                            <Image {...image} />
                        </Link>
                    )
                ) : (
                    <Image {...image} />
                )}
            </div>
        </div>
    );
};

export default HeaderImage;
