import { useStyles } from '@archipro-design/aria';
import type { HeaderOverlayProps } from '@modules/home/component/header-module/HeaderOverlay';
import * as S from './HeaderOverlays.style';
import HeaderOverlayAdWrapper from '@modules/home/component/header-module/HeaderOverlayAdWrapper';
import HeaderOverlayBannerWrapper from './HeaderOverlayBannerWrapper';

interface HeaderOverlaysProps {
    overlays: HeaderOverlayProps[];
    current: number;
    loggedIn: boolean;
}

const HeaderOverlays = ({
    overlays,
    current,
    loggedIn,
}: HeaderOverlaysProps) => {
    const { css } = useStyles();

    return (
        <>
            {overlays.map((overlayProps, k) => {
                if (overlayProps.advertCreative) {
                    return (
                        <div
                            key={k}
                            className={css(S.BaseStyle)}
                            style={{
                                display: current === k ? 'block' : 'none',
                            }}
                        >
                            <HeaderOverlayAdWrapper
                                title={overlayProps.title}
                                ctaLink={overlayProps.ctaLink}
                                ctaLabel={overlayProps.ctaLabel}
                                pinType={overlayProps.pinType}
                                id={overlayProps.id}
                                category={overlayProps.category}
                                loggedIn={loggedIn}
                                advertCreative={overlayProps.advertCreative}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={k}
                            className={css(S.BaseStyle)}
                            style={{
                                display: current === k ? 'block' : 'none',
                            }}
                        >
                            <HeaderOverlayBannerWrapper
                                title={overlayProps.title}
                                ctaLink={overlayProps.ctaLink}
                                ctaLabel={overlayProps.ctaLabel}
                                pinType={overlayProps.pinType}
                                id={overlayProps.id}
                                category={overlayProps.category}
                                loggedIn={loggedIn}
                                advertCreative={overlayProps.advertCreative}
                                position={k + 1}
                            />
                        </div>
                    );
                }
            })}
        </>
    );
};

export default HeaderOverlays;
