import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';

export const CarouselSection: StyleRule = ({ theme }) => ({
    position: `relative`,
    paddingLeft: 0,
    margin: pxArrayToRem([0]),

    [`& .swiper-wrapper`]: {
        [`& .swiper-slide:first-child`]: {
            marginLeft: pxToRem(18),
        },
        [`& .swiper-slide:last-child`]: {
            marginRight: pxToRem(18),
        },
    },

    [theme.screen.tablet]: {
        margin: pxArrayToRem([0, -50, 0]),
        paddingLeft: 0,

        [`& .swiper-wrapper`]: {
            [`& .swiper-slide:first-child`]: {
                marginLeft: pxToRem(50),
            },
            [`& .swiper-slide:last-child`]: {
                marginRight: pxToRem(50),
            },
        },
    },

    [theme.screen.clamped]: {
        margin: 0,
    },
});

export const Slide: StyleRule = ({ theme }) => ({
    [`&.swiper-slide`]: {
        width: pxToRem(323),
        marginRight: pxToRem(10),
        [theme.screen.tablet]: {
            width: pxToRem(811),
            marginRight: pxToRem(18),
        },
    },
});
