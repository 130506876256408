import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    pointerEvents: 'auto',
    zIndex: theme.siteVariables.zIndexes.foreground,

    paddingTop: pxToRem(5),
    [theme.screen.min.tablet]: {
        right: pxToRem(50),
        bottom: pxToRem(50),
        backgroundColor: theme.siteVariables.colors.charcoal[260],
        position: 'absolute',
    },
});
