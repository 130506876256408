import {
    Flex,
    Caption,
    Button,
    useStyles,
    useAppDisplayModeContext,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './HeaderOverlay.style';
import { CustomArrowRight } from '@archipro-design/icons';

import type { SaveToDesignBoardProps } from '@modules/design-board/component/SaveToDesignBoard';
import type { AdvertCreative } from '@modules/adverts/type';
import type {
    AdEventHandlerOnClick,
    AdTrackingEvent,
} from '@archipro-website/tracker';
import { useTracker } from '@archipro-website/tracker';
import { createLinkedComponentParams } from '~/utils/urlHelper';

export interface HeaderOverlayProps {
    title: string;
    subTitle?: string;
    ctaLink: string;
    isExternal?: boolean;
    ctaLabel: string;
    pinType: SaveToDesignBoardProps['itemType'];
    id: number;
    category: string;
    loggedIn: boolean;
    onClick?: AdEventHandlerOnClick;
    advertCreative?: AdvertCreative | null;
}

const HeaderOverlay = ({
    title,
    subTitle,
    ctaLink,
    ctaLabel,
    category,
    loggedIn,
    onClick,
    advertCreative,
}: HeaderOverlayProps) => {
    const { css } = useStyles({ loggedIn });
    const { desktop } = useAppDisplayModeContext();
    const tracker = useTracker();

    const overlayClick = (adEventType: AdTrackingEvent) => {
        if (onClick) {
            onClick(adEventType);
        }
    };

    return (
        <div className={`${css(S.BaseStyle)}`}>
            <Flex column={true}>
                <div>
                    <Caption
                        className={css(S.CategoryStyle)}
                        variant="03"
                        uc={true}
                    >
                        {category}
                    </Caption>
                </div>
                <div className={css(S.TileStyle)}>
                    <Caption
                        variant="03"
                        uc={false}
                        maximumLines={3}
                        weight="regular"
                    >
                        {title}
                    </Caption>
                </div>
                {subTitle && (
                    <div className={css(S.SubTileStyle)}>
                        <Caption
                            variant="04"
                            uc={false}
                            maximumLines={3}
                            weight="regular"
                        >
                            {subTitle}
                        </Caption>
                    </div>
                )}
                <Button
                    className={css(S.CTAButton)}
                    iconAfter={<CustomArrowRight />}
                    size={14}
                    prefetch="intent"
                    variables={{
                        iconPadding: 0,
                        buttonSize14IconXMargin: !desktop
                            ? pxToRem(8)
                            : pxToRem(4),
                    }}
                    {...createLinkedComponentParams(
                        tracker,
                        'Ad_CTAClick',
                        ctaLink,
                        {
                            libraryItemID: advertCreative?.professional.id,
                            professionalID: advertCreative?.professional.id,
                        },
                        null,
                        overlayClick
                    )}
                >
                    {ctaLabel}
                </Button>
            </Flex>
        </div>
    );
};

export default HeaderOverlay;
