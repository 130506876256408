import { useAppData } from '~/modules/root';
import type { CategoryNavItem } from '../common/CategoryNav';
import CategoryNav from '../common/CategoryNav';
import type { ButtonProps } from '@archipro-design/aria';
import { useTheme } from '@archipro-design/aria';

interface Props {
    shopNavEnabled?: boolean;
}
const HeaderCategorynav = ({ shopNavEnabled }: Props) => {
    const { topNavigation } = useAppData();
    const theme = useTheme();

    const categoryNavItems: CategoryNavItem[] = [
        ...(shopNavEnabled
            ? [
                  {
                      id: '-1',
                      name: 'Shop',
                      link: '/products?forSale=1',
                      variables: {
                          primaryButtonBackgroundColorNormal:
                              theme.siteVariables.contextualColors.danger[
                                  '300'
                              ],
                      } as ButtonProps['variables'],
                  },
              ]
            : []),
        ...(topNavigation.map((nav) => ({
            id: String(nav.id),
            name: String(nav.label),
            link: String(nav.href),
        })) ?? []),
    ];
    return <CategoryNav items={categoryNavItems} />;
};

export default HeaderCategorynav;
