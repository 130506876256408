import SectionHeader from '@modules/home/component/common/SectionHeader';
import {
    Button,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './SectionTitleSlider.style';
import { Link } from '@remix-run/react';

interface SectionTitleSliderProps {
    title: string;
    buttonLink: string;
    buttonTitle: string;
    categorySlot?: React.ReactNode;
    children?: React.ReactNode;
}

const SectionTitleSlider = ({
    title,
    buttonLink,
    buttonTitle,
    categorySlot,
    children,
}: SectionTitleSliderProps) => {
    const { mobile: isMobile } = useAppDisplayModeContext();
    const { css } = useStyles();

    return (
        <div className={css(S.ProfessionalModuleStyle)}>
            <SectionHeader title={title} underline={false} />
            {categorySlot}
            {children}
            {isMobile && (
                <div className={css(S.ButtonWrapMobileStyle)}>
                    <Button
                        as={Link}
                        to={buttonLink}
                        prefetch="intent"
                        transparent
                        outlined
                        size={16}
                        fluid
                        className={css(S.ButtonMobileStyle)}
                        variables={(siteVars) => ({
                            primaryOutlinedButtonBorderNormal: `1px solid ${siteVars?.colors.charcoal[100]}`,
                            primaryOutlinedButtonTextColorNormal:
                                siteVars?.colors.charcoal[250],
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SectionTitleSlider;
