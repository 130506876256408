import {
    avatarClassName,
    professionalTileClassName,
    professionalTileSlotClassNames,
    pxArrayToRem,
    pxToRem,
    buttonClassName,
    tileImageClassName,
} from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import { tileImageSlotClassNames } from '@archipro-design/aria';

export const TileWrapper: StyleRule = ({ theme }) => ({
    display: 'block',

    [`& .${svgIconClassName}`]: {
        width: pxToRem(16),
        height: pxToRem(16),
    },

    [`& .${buttonClassName}`]: {
        padding: pxToRem(8),
    },

    [theme.screen.laptop]: {
        [`& .${svgIconClassName}`]: {
            width: pxToRem(24),
            height: pxToRem(24),
        },

        [`& .${buttonClassName}`]: {
            padding: pxToRem(12),
        },
    },
});

export const ProfessionalTile: StyleRule = ({ theme }) => ({
    [`&.${professionalTileClassName}`]: {
        [`& .${professionalTileSlotClassNames.content}`]: {
            padding: `${pxArrayToRem([16, 0, 0, 0])}`,
            alignItems: 'center',
            flexDirection: 'row',

            '& > .ui-flex': {
                paddingRight: pxToRem(10),
            },

            [`& .${professionalTileSlotClassNames.title}`]: {
                whiteSpace: 'break-spaces',
            },

            [`& .${avatarClassName}`]: {
                marginRight: pxToRem(16),
                marginTop: 0,
                marginBottom: 0,
                width: pxToRem(90),
                height: pxToRem(90),
            },
        },

        [`& .${professionalTileSlotClassNames.headerWrap}`]: {
            width: 'unset',
            paddingLeft: 0,
            paddingRight: '0 !important',
        },

        [`& .${professionalTileSlotClassNames.textWrap}`]: {
            padding: 0,
            overflow: 'hidden',

            [`& .${professionalTileSlotClassNames.title}`]: {
                marginBottom: 0,
            },

            [`& .${professionalTileSlotClassNames.category}`]: {
                marginTop: pxToRem(5),
                marginBottom: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },

        [`& .${tileImageClassName}`]: {
            height: pxToRem(185),

            [`& .${tileImageSlotClassNames.imageContainer}`]: {
                height: pxToRem(185),
            },
        },
    },

    [theme.screen.tablet]: {
        [`&.${professionalTileClassName}`]: {
            [`& .${professionalTileSlotClassNames.content}`]: {
                padding: pxArrayToRem([28, 0, 0]),
                alignItems: 'flex-start',

                '& > .ui-flex': {
                    paddingRight: 'unset',
                },

                [`& .${professionalTileSlotClassNames.title}`]: {
                    whiteSpace: 'unset',
                },

                [`& .${avatarClassName}`]: {
                    marginRight: pxToRem(30),
                },
            },

            [`& .${professionalTileSlotClassNames.textWrap}`]: {
                overflow: 'visible',

                [`& .${professionalTileSlotClassNames.category}`]: {
                    marginBottom: pxToRem(16),
                },
            },

            [`& .${tileImageClassName}`]: {
                height: pxToRem(464),

                [`& .${tileImageSlotClassNames.imageContainer}`]: {
                    height: pxToRem(464),
                },
            },
        },
    },
});
