import { useStyles } from '@archipro-design/aria';
import * as S from './FeaturedProfessionals.style';

import type { CustomLogoWallProps } from './CustomLogoWall';
import CustomLogoWall from './CustomLogoWall';

export interface FeaturedProfessionalsProps extends CustomLogoWallProps {}

const FeaturedProfessionals = ({ tiles }: FeaturedProfessionalsProps) => {
    const { css } = useStyles();

    return (
        <div>
            <div className={css(S.Header)}>
                {`We’re your primary gateway to the world of architecture, design, and building`}
            </div>
            <CustomLogoWall tiles={tiles} />
        </div>
    );
};

export default FeaturedProfessionals;
