import type { Tracker, TrackingEvent } from '@archipro-website/tracker';
import type { HomeSectionType, HomeTileTrackerData } from '../type';
import type { Logger } from '@archipro-website/logger';

/**
 * Get the row number from position.
 * @param pos position starts from 0
 * @param columnPerRow column count per row
 * @returns row number starts from 1
 */
const getRow = (pos: number, columnPerRow: number): number => {
    return Math.floor(pos / columnPerRow) + 1;
};

/**
 * Get the column number from position.
 * @param pos position starts from 0
 * @param columnPerRow column count per row
 * @returns column number starts from 1
 */
const getColumn = (pos: number, columnPerRow: number): number => {
    return (pos % columnPerRow) + 1;
};

export const getTrackerData = (props: {
    itemID: number;
    professionalID: number;
    sectionType: HomeSectionType;
    position: number; // starts from 0
    columnPerRow: number;
}): HomeTileTrackerData => {
    const { itemID, professionalID, sectionType, position, columnPerRow } =
        props;
    // TODO: clean unused fields in trackerData
    return {
        professionalID: professionalID ?? 0,
        row: getRow(position, columnPerRow),
        column: getColumn(position, columnPerRow),
        columnPerRow: columnPerRow,
        position,
        sectionOrder: 0,
        itemID,
        sectionType,
    };
};

export const fireHomeTileEvent = (
    event: TrackingEvent,
    url: string,
    data: HomeTileTrackerData,
    tracker: Tracker,
    logger: Logger
) => {
    const { row, column, columnPerRow, itemID } = data;
    const logData = [row, column, columnPerRow, itemID];
    try {
        tracker.log(event, {
            url: new URL(url),
            label: url,
            targetTracker: 'archiproTrackerV2',
            data: {
                // format: [Row,Column,ColumnsPerRow,ItemID]
                ExtraData: JSON.stringify(logData),
            },
        });
    } catch (e) {
        logger.error('fireHomeTileEvent', e);
    }
};
