import type { HomeSectionType } from '../type';
import { HOME_SECTION_COLUMNS } from '../type';

// Please reference to this ticket for the calculation rules.
// https://archipro.atlassian.net/browse/BA-735

/**
 * Get the column count for the specific row of the section.
 * @param section
 * @param desktop
 * @returns
 */
export const getHomeSectionColumn = (
    section: HomeSectionType,
    desktop: boolean
) => {
    const device = !desktop ? 'mobile' : 'desktop';
    return HOME_SECTION_COLUMNS[device][section];
};
