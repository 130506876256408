import type { HomeTileViewModel } from '@modules/home/type';
import type { ProjectHomeTileProps } from '@modules/home/util/project-home-tile-view-model.server';
import type { HomeArticleTileProps } from '@modules/home/type';
import {
    Grid,
    BannerAdTile,
    useStyles,
    FeaturedArticle,
    pxToRem,
    pxArrayToRem,
    bannerAdTileSlotClassNames,
    useTheme,
    useAppDisplayModeContext,
    Button,
    HotButtons,
} from '@archipro-design/aria';

import { CustomArrowRight } from '@archipro-design/icons';

import * as S from './InspirationModule.style';
import { Link } from '@remix-run/react';
import { useInViewport } from 'ahooks';
import { useRef, useState } from 'react';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { useHydrated } from 'remix-utils';
import ArchizeenImpressionTile from '~/modules/tile/component/archizeen-tile/ArchizeenImpressionTile';
import { canHomePin } from '../../util/home-pin-helpers';
import { useToggleHomePin } from '../../hook/use-toggle-home-pin';
import { useAppData } from '~/modules/root';
import MessageModal from '../common/MessageModal';
import { getHotButtons } from '~/modules/directory/util/get-hot-buttons';
import { useIsWebmLegacyDevice } from '@modules/root/hook/use-is-webm-legacy-device';
import { useCompatibleVideoSources } from '@modules/root/hook/use-compatible-video-sources';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import { useLogImpression, useTracker } from '@archipro-website/tracker';

interface InspirationModuleProps {
    projectTiles: HomeTileViewModel<ProjectHomeTileProps>[];
    articleTiles: HomeArticleTileProps[];
    isMobile: boolean;
}

const InspirationModule = ({
    projectTiles,
    articleTiles,
    isMobile,
}: InspirationModuleProps) => {
    const { css } = useStyles();
    const theme = useTheme();
    const appDisplayMode = useAppDisplayModeContext();
    const { desktop } = appDisplayMode;

    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editProfile;

    const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            setPinErrorMessage(error.Message);
        }
    });

    const projects = projectTiles.map((project) => {
        return (
            <InspirationProject
                key={project.tile.projectLink}
                {...project}
                isMobile={isMobile}
            />
        );
    });

    const articles = articleTiles.map((article, index) => {
        const { tile } = article;
        const { link, image, title, category, date } = tile;

        if (isMobile) {
            //TODO: display featured article once confirm
            return (
                <ArchizeenImpressionTile
                    size="dynamic"
                    variant="04"
                    key={link}
                    as={Link}
                    to={link}
                    prefetch={'intent'}
                    image={{
                        src: image,
                        alt: `${title} banner`,
                        height: 131,
                        width: 118,
                    }}
                    label={{
                        children: category,
                        className: css(S.FeaturedArticleCategory),
                    }}
                    title={{
                        children: title,
                        maximumLines: 2,
                        className: css(S.FeaturedArticleTitle),
                    }}
                    className={css(S.FeaturedArticleTileMobile)}
                    date={date}
                    variables={{
                        dynamicSizingGapWidth: pxToRem(12),
                    }}
                />
            );
        }

        const hotButtons = getHotButtons({
            item: tile,
            itemType: 'product',
            appDisplayMode,
            canEditAdmin,
            canEdit: canEditAdmin,
            pinned: !!tile.homePinned,
            onPinClick: canHomePin(rootGrants)
                ? () => {
                      toggleHomePin(Number(tile.ID), !tile.homePinned);
                  }
                : undefined,
        });

        return (
            <FeaturedArticle
                key={link}
                onFavourite={() => {}}
                image={{
                    alt: `${title} banner`,
                    src: image,
                    width: 780,
                    height: 620,
                    loading: 'lazy',
                    overlay: {
                        children: <div />,
                        as: Link,
                        to: link,
                        prefetch: 'intent',
                        icon: <div />,
                        alwaysVisible: true,
                    },
                    sizes: getImagesSizes(780),
                    variables: {
                        overlayBackgroundColor: 'transparent',
                    },
                }}
                date={date}
                categoryTitle={{
                    children: category,
                    className: css(S.FeaturedArticleCategory),
                }}
                variant={index % 2 ? '03' : '02'}
                title={{
                    children: title,
                    maximumLines: 4,
                    className: css(S.FeaturedArticleTitle),
                    as: Link,
                    to: link,
                    prefetch: 'intent',
                }}
                className={css(S.FeaturedArticleTile)}
                contentButton={{
                    children: 'READ ARTICLE',
                    iconAfter: <CustomArrowRight />,
                    as: Link,
                    to: link,
                    prefetch: 'intent',
                    size: 24,
                    variables: {
                        primaryButtonBackgroundColorNormal:
                            theme.siteVariables.colors.primitive.black,
                        primaryButtonBackgroundColorHover:
                            theme.siteVariables.colors.charcoal['250'],
                        iconPadding: 0,
                    },
                }}
                variables={{
                    width: '100%',
                    imageWidth: pxToRem(780),
                    imageHeight: pxToRem(620),
                    tilePadding: pxArrayToRem([0, 110]),
                    contentGridPadding: pxArrayToRem(
                        index % 2 ? [0, 160, 0, 0] : [0, 0, 0, 160]
                    ),
                    contentGridColumns: '100%',
                }}
                topActions={
                    hotButtons ? <HotButtons items={hotButtons} /> : undefined
                }
            />
        );
    });

    const tiles = [];
    const counts = Math.min(projects.length, articles.length);
    // eslint-disable-next-line array-callback-return
    Array.from({ length: counts }).map((_, idx) => {
        tiles.push(articles[idx], projects[idx]);
    });

    tiles.push(...projects.slice(counts), ...articles.slice(counts));

    return (
        <div>
            <Grid columns={'100%'} className={css(S.BaseStyle)}>
                {tiles}
            </Grid>
            {!desktop && (
                <div className={css(S.ButtonWrapMobileStyle)}>
                    <Button
                        as={Link}
                        prefetch="intent"
                        to={'/articles'}
                        variant="outlined"
                        size={16}
                        fluid
                        className={css(S.ButtonMobileStyle)}
                        variables={(siteVars) => ({
                            primaryOutlinedButtonBorderNormal: `1px solid ${siteVars?.colors.charcoal[100]}`,
                            primaryOutlinedButtonTextColorNormal:
                                siteVars?.colors.charcoal[250],
                        })}
                    >
                        View more on archizeen
                    </Button>
                </div>
            )}
            {pinErrorMessage && (
                <MessageModal
                    title={'Warning'}
                    content={pinErrorMessage}
                    open={true}
                    onConfirm={() => setPinErrorMessage(null)}
                />
            )}
        </div>
    );
};

export default InspirationModule;

export const InspirationProject = (
    project: HomeTileViewModel<ProjectHomeTileProps> & {
        isMobile: boolean;
    }
) => {
    const ref = useRef(null);
    const tracker = useTracker();
    useLogImpression({
        data: {
            professionalID: project.professionalID,
            itemID: project.id,
            type: 'Potm_Homepage',
        },
        ref,
    });
    const { desktop } = useAppDisplayModeContext();
    const config = useConfigContext();
    const assetsBaseUrl = config.assetsBaseUrl;

    const isHydrated = useHydrated();

    const [inViewport] = useInViewport(ref, {
        threshold: 0.75,
    });
    const { css } = useStyles({ inViewport });

    const { link, largeImages, tile, isMobile } = project;
    const asParam = tile.isExternal ? 'a' : Link;
    const linkProps = tile.isExternal
        ? {
              href: link,
          }
        : {
              to: link,
          };

    const isWebmLegacyDevice = useIsWebmLegacyDevice();
    const videoSources = useCompatibleVideoSources(
        isWebmLegacyDevice,
        tile.videoUrl
    );

    let poster = tile.videoPoster;
    if (poster) {
        if (!poster.startsWith('/')) {
            poster = `/${poster}`;
        }
        if (poster.startsWith('/assets/')) {
            poster = `${assetsBaseUrl}${poster}`;
        }
    }

    const trackClick = (clickedTarget: string) => {
        tracker.log('PotmHomepageClick', {
            url: new URL(window.location.href),
            targetTracker: ['archiproTracker'],
            data: {
                ExtraData: JSON.stringify({
                    libraryItemId: project.id,
                    clickedTarget: clickedTarget,
                }),
            },
        });
    };

    const trackProfessionalClick = () => {
        tracker.log('PotmProfessionalHomepageClick', {
            url: new URL(window.location.href),
            targetTracker: ['archiproTracker'],
            data: {
                ExtraData: JSON.stringify({
                    libraryItemId: project.id,
                    professionalID: project.professionalID,
                }),
            },
        });
    };

    const subtitle = tile.subtitleOverride
        ? {
              children: tile.subtitleOverride,
              link: false,
              styles: {
                  [`&.${bannerAdTileSlotClassNames.description}`]: {
                      ...(!desktop && {
                          marginTop: 0,
                      }),
                  },
              },
              variables: {
                  ...(!desktop && { fontSize: pxToRem(13) }),
              },
          }
        : {
              children: `By ${tile.professionalName}`,
              link: true,
              styles: {
                  [`&.${bannerAdTileSlotClassNames.description}`]: {
                      textDecoration: 'underline',
                      ...(!desktop && {
                          marginTop: 0,
                      }),
                  },
              },
              as: Link,
              to: tile.professionalLink,
              prefetch: 'intent',
              variables: {
                  ...(!desktop && { fontSize: pxToRem(13) }),
              },
          };

    return (
        <div ref={ref} className={css(S.FeaturedProject)}>
            {largeImages && largeImages.length > 0 ? (
                <BannerAdTile
                    variant={'01'}
                    onTitleClick={() => trackClick('title')}
                    onCTAClick={() => trackClick('cta')}
                    onTileClick={() => trackClick('video')}
                    onSubtitleClick={trackProfessionalClick}
                    images={largeImages.map((image) => {
                        return {
                            alt: `${tile.projectName} banner image`,
                            src: image,
                            width: isMobile ? 414 : 1820,
                            height: isMobile ? 264 : 811,
                            loading: 'lazy',
                            sizes: getImagesSizes({
                                desktop: 1820,
                                mobile: 414,
                            }),
                            overlay: {
                                children: <div />,
                                as: asParam,
                                ...linkProps,
                                prefetch: 'intent',
                                icon: <div />,
                                alwaysVisible: true,
                                variables: {
                                    backgroundColor: 'transparent',
                                },
                            },
                        };
                    })}
                    description={subtitle}
                    variables={{
                        contentWrapSpacing: isMobile
                            ? pxArrayToRem([2, 18, 0])
                            : pxArrayToRem([40, 0, 0, 923]),
                    }}
                    title={{
                        children: tile.projectName,
                        as: asParam,
                        ...linkProps,
                        prefetch: 'intent',
                        styles: {
                            [`&.${bannerAdTileSlotClassNames.title}`]: {
                                ...(!desktop && {
                                    marginBottom: 0,
                                }),
                            },
                        },
                    }}
                    company={undefined}
                    actionButton={
                        desktop && {
                            variant: 'contained',
                            children:
                                tile.ctaTextOverride ?? 'DISCOVER PROJECT',
                            iconAfter: <CustomArrowRight />,
                            as: asParam,
                            ...linkProps,
                            prefetch: 'intent',
                            size: 24,
                            variables: {
                                iconPadding: 0,
                            },
                        }
                    }
                    size={'medium'}
                    {...(videoSources &&
                        isHydrated && {
                            tileVideo: {
                                src: videoSources,
                                width: isMobile ? 414 : 1820,
                                height: isMobile ? 274 : 811,
                                playing: inViewport,
                                hoverTrigger: null,
                                playsInline: true,
                                topContent: (
                                    <div className={css(S.VideoOverlay)} />
                                ),
                                as: asParam,
                                ...linkProps,
                                hideFavourite: true,
                                lazy: !!tile.videoLazy,
                                poster: poster,
                                styles: {
                                    width: '100%',
                                },
                            },
                        })}
                />
            ) : null}
        </div>
    );
};
