import type { StyleRule } from '@archipro-design/aria';
import {
    projectTileClassName,
    projectTileSlotClassNames,
    pxToRem,
} from '@archipro-design/aria';

export const TileWrapper: StyleRule = ({ theme }) => ({
    display: 'block',

    [`& .${projectTileClassName}`]: {
        [`& .${projectTileSlotClassNames.projectName}`]: {
            padding: 0,
            [theme.screen.laptop]: {
                fontSize: pxToRem(16),
            },
        },

        [`& .${projectTileSlotClassNames.professionalName}`]: {
            padding: 0,
            fontSize: pxToRem(10),
            [theme.screen.laptop]: {
                fontSize: pxToRem(18),
            },
        },
    },
});
