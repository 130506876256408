import * as S from './GuestTopNav.style';
import {
    useAppDisplayModeContext,
    Flex,
    Logo,
    Button,
    useStyles,
    useTheme,
    pxToRem,
} from '@archipro-design/aria';
import { Link } from '@remix-run/react';

import { useLoginModal } from '@modules/design-board';

const GuestTopNav = () => {
    const { css } = useStyles();
    const theme = useTheme();
    const { desktop } = useAppDisplayModeContext();
    const { showLoginModal, loginModal } = useLoginModal({
        authSource: 'homeModule',
    });

    return (
        <Flex className={css(S.GuestTopNavContainer)}>
            <Logo
                className={css(S.Logo)}
                showFull={true}
                variables={{
                    logoHeight: pxToRem(desktop ? 26 : 17),
                    logoWidth: 'auto',
                    logoPrimaryColor:
                        theme.siteVariables.colors.primitive.black,
                }}
                as={Link}
                to="/"
            />
            <Flex className={css(S.RightHandNav)}>
                {desktop && (
                    <Flex className={css(S.Links)}>
                        <Link
                            className={css(S.Link)}
                            to={'/about-us'}
                            prefetch="intent"
                        >
                            About
                        </Link>
                        <Link
                            className={css(S.Link)}
                            to={'/become-a-pro'}
                            prefetch="intent"
                        >
                            Become a pro
                        </Link>
                        <Link
                            className={css(S.Link)}
                            to={'/sell-on-archipro'}
                            prefetch="intent"
                        >
                            Sell on ArchiPro
                        </Link>
                    </Flex>
                )}
                <Flex className={css(S.Actions)}>
                    <Button
                        className={css(S.Primary)}
                        onClick={showLoginModal}
                        size={16}
                    >
                        Sign up
                    </Button>
                    <Button
                        className={css(S.Secondary)}
                        onClick={showLoginModal}
                        size={16}
                        variant={'outlined'}
                        variables={{
                            primaryOutlinedButtonBorderNormal: `1px solid ${theme.siteVariables.colors.primitive.black}`,
                        }}
                    >
                        Log in
                    </Button>
                </Flex>
            </Flex>
            {loginModal}
        </Flex>
    );
};

export default GuestTopNav;
