import { useRef, useState } from 'react';
import type { AdminTileProps } from '@archipro-design/aria';
import {
    ProductTile,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import { injectLoadingToImagePropsCollection } from '@modules/home/util/image-to-image-props';
import type { FavouriteItem } from '~/modules/design-board/type/design-board-types';
import type { ProductHomeTileProps } from '@modules/home/util/product-home-tile-view-model.server';
import type { HomeTileTrackerData } from '../../type';
import useHomeTileEventHandler from '../../hook/use-home-tile-handler';
import type { AdvertCreative } from '@modules/adverts/type';
import * as S from './HomeProductTile.style';
import VariantDot from '@modules/tile/component/product-tile/VariantDot';
import type { Tracker } from '@archipro-website/tracker';
import { createLinkedComponentParams } from '~/utils/urlHelper';

type HomeProductTileProps = {
    tracker: Tracker;
    isMobile: boolean;
    id: number;
    link: string;
    tile: ProductHomeTileProps;
    favouriteItem: FavouriteItem;
    onProfessionalClick: (url: string) => void;
    trackerData?: HomeTileTrackerData;
    ad?: AdvertCreative;
} & Pick<AdminTileProps, 'topActions'>;

const HomeProductTile = ({
    tracker,
    isMobile,
    id,
    tile,
    favouriteItem,
    trackerData,
    ad,
    topActions,
}: HomeProductTileProps) => {
    const [beenFocused, setBeenFocused] = useState(false);
    const tileRef = useRef(null);
    const { onClick } = useHomeTileEventHandler(trackerData, tileRef, ad);
    const { mobile } = useAppDisplayModeContext();

    const onTileInteraction = () => {
        if (!beenFocused) {
            setBeenFocused(true);
        }
    };

    const {
        productLink,
        professionalName,
        image,
        isMultiplied,
        canPurchase: _,
        homePinned: _homePinned,
        colorsCount,
        ...rest
    } = tile;

    const { css } = useStyles({ isMultiplied });

    const imgPropsCol = injectLoadingToImagePropsCollection(
        image,
        beenFocused ? 'eager' : 'lazy',
        isMobile,
        'product',
        rest.productName
    );

    const variants = tile.variants?.map((v) => {
        return {
            key: `variant-dot-${v.id}`,
            children: (
                <VariantDot
                    size={mobile ? 9 : 13}
                    colorCode={v.code}
                    description={v.label}
                    image={v.image}
                />
            ),
        };
    });

    return (
        <div onMouseEnter={onTileInteraction} onTouchStart={onTileInteraction}>
            <ProductTile
                ref={tileRef}
                image={imgPropsCol}
                className={css(S.HomeProductTile)}
                {...rest}
                {...createLinkedComponentParams(
                    tracker,
                    'Ad_CTAClick',
                    productLink,
                    {
                        libraryItemID: trackerData?.itemID,
                        professionalID: trackerData?.professionalID,
                    },
                    null,
                    onClick
                )}
                prefetch="intent"
                professionalName={professionalName}
                variants={variants}
                variant={mobile ? '02' : '01'}
                additionalVariantCount={colorsCount - (variants?.length ?? 0)}
                size={mobile ? 'medium' : 'dynamic'}
                onFavourite={(e) => {
                    favouriteItem.onClick(e, id);
                }}
                hideFavourite={id === 0}
                isFavourited={id ? favouriteItem.favourited(id) : false}
                variables={{
                    dynamicSizingWidth: isMobile ? 204 : 307,
                    dynamicSizingGapWidth: 6,
                    tilePadding: pxToRem(3),
                }}
                topActions={topActions}
                newDesignEnabled={true}
            />
        </div>
    );
};

export default HomeProductTile;
