import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    buttonClassName,
    labelClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const BaseStyle: StyleRule = ({ theme }) => ({
    cursor: 'default',
    padding: pxArrayToRem([16, 0, 42]),
    margin: pxArrayToRem([0, 18]),
    borderBottom: `1px solid rgba(0,0,0,0.1)`,

    '& .cta-icon-target': {
        transition: '0.3s',
        opacity: 0,
    },
    '&:hover .cta-icon-target': {
        opacity: 1,
        transition: '0.3s',
        marginLeft: pxToRem(10),
    },

    [theme.screen.tablet]: {
        padding: pxArrayToRem([31, 0, 31, 43]),
        margin: 0,
        position: 'absolute',
        width: pxToRem(485),
        right: pxToRem(-13),
        bottom: pxToRem(219),
        zIndex: theme.siteVariables.zIndexes.menuItem,
        borderBottom: 'none',
    },
});

export const TileStyle: StyleRule = ({ theme }) => ({
    paddingTop: pxToRem(8),
    maxWidth: pxToRem(300),
    '> div': {
        ...theme.siteVariables.textStyles.Heading.Roman55,
        lineHeight: theme.siteVariables.lineHeightDefault,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        fontSize: pxToRem(30),
        paddingBottom: pxToRem(3),
        marginBottom: pxToRem(-3),
        [theme.screen.tablet]: {
            fontSize: pxToRem(44),
        },
    },
    [theme.screen.tablet]: {
        maxWidth: 'none',
    },
});

export const CategoryStyle: StyleRule = ({ theme }) => ({
    letterSpacing: `0.14em`,
    marginBottom: pxToRem(8),
    lineHeight: '110%',
    fontSize: pxToRem(11.9),
    color: theme.siteVariables.colors.primitive.black,
    ...theme.siteVariables.textStyles.Heading.Medium65,

    [theme.screen.tablet]: {
        letterSpacing: `0.19em`,
        marginBottom: pxToRem(6),
        fontSize: pxToRem(16),
        color: theme.siteVariables.colors.charcoal[250],
        ...theme.siteVariables.textStyles.Heading.Roman55,
    },
});

export const SubTileStyle: StyleRule = () => ({
    '> div': {
        marginTop: pxToRem(4),
        lineHeight: '120%',
        letterSpacing: '0.02em',
    },
});

export const CTAButton: StyleRule<{ loggedIn: boolean }> = ({ theme }) => ({
    [`&.${buttonClassName}`]: {
        padding: pxArrayToRem([16.5, 35]),
        width: 'fit-content',
        marginTop: pxToRem(44),
        border: 'none',

        [`& .${labelClassName}`]: {
            fontSize: pxToRem(13),
            lineHeight: theme.siteVariables.lineHeightSmallest,
            textTransform: 'uppercase',
        },

        [`& .${svgIconClassName}`]: {
            width: pxToRem(24),
            height: pxToRem(24),
        },

        // Change font only for mobile
        [theme.screen.max.laptop]: {
            [`& .${labelClassName}`]: {
                ...theme.siteVariables.textStyles.Heading.Medium65,
            },
        },

        [theme.screen.tablet]: {
            marginTop: pxToRem(28),
            padding: pxArrayToRem([11.5, 23.5]),
        },
    },
});
