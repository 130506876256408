import { Grid, useStyles } from '@archipro-design/aria';
import * as S from './CustomLogoWall.style';

import type { CustomLogoTileProps } from './CustomLogoTile';
import CustomLogoTile from './CustomLogoTile';

export interface CustomLogoWallProps {
    tiles: CustomLogoTileProps[];
}

const CustomLogoWall = ({ tiles }: CustomLogoWallProps) => {
    const { css } = useStyles();

    return (
        <div className={css(S.Container)}>
            <Grid columns={8} className={css(S.GridWrapper)}>
                {tiles.map((tile) => (
                    <CustomLogoTile key={tile.linkHref} {...tile} />
                ))}
            </Grid>
        </div>
    );
};

export default CustomLogoWall;
