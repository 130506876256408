import { useState, useEffect, useRef } from 'react';

import { useStyles, Carousel, Image } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import * as S from './HeaderBanner.style';
import HeaderImage from '@modules/home/component/header-module/HeaderImage';
import type { HeaderOverlayProps } from '@modules/home/component/header-module/HeaderOverlay';
import type SwiperCore from 'swiper';
import { BANNER_HEIGHT, BANNER_HEIGHT_MOBILE } from '@modules/home/const';
import { CustomArrowRight, CustomArrowLeft } from '@archipro-design/icons';
import HeaderOverlays from '@modules/home/component/header-module/HeaderOverlays';
import HeaderProgress from '@modules/home/component/header-module/HeaderProgress';
import { useDocumentVisibility, useInViewport } from 'ahooks';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { ClientOnly } from 'remix-utils';

interface HeaderBannerProps {
    images: {
        key: string;
        src: string;
    }[];
    overlays: HeaderOverlayProps[];
    delay: number;
    isMobile: boolean;
    loggedIn: boolean;
    onSignupClick: () => void;
}

const HeaderBanner = ({
    images,
    overlays,
    delay,
    isMobile,
    loggedIn,
}: HeaderBannerProps) => {
    const bannerHeight = isMobile ? BANNER_HEIGHT_MOBILE : BANNER_HEIGHT;
    const bannerWidth = isMobile ? 414 : 749;

    const { css } = useStyles();
    const documentVisible = useDocumentVisibility();
    const ref = useRef(null);
    const [currentTile, setCurrentTile] = useState<number>(0);
    const [animate, setAnimate] = useState<boolean>(false);
    const [headerSwiper, setHeaderSwiper] = useState<SwiperCore | null>(null);
    const [inViewport] = useInViewport(ref, {
        threshold: 0.75,
    });

    useEffect(() => {
        if (headerSwiper) {
            headerSwiper?.on('slideChangeTransitionEnd', () => {
                setCurrentTile(headerSwiper.realIndex);
            });
        }
    }, [headerSwiper]);

    useEffect(() => {
        if (documentVisible !== 'visible') {
            setAnimate(false);
        } else {
            setAnimate(true);
        }
    }, [documentVisible]);

    return (
        <div className={css(S.BaseStyle)}>
            <ClientOnly
                fallback={
                    <div
                        className={css(S.FallbackImageContainer)}
                        style={{
                            width: pxToRem(bannerWidth),
                            height: pxToRem(bannerHeight),
                        }}
                    >
                        {images[0] && (
                            <Image
                                src={images[0].src}
                                layout={'fill'}
                                width={pxToRem(bannerWidth)}
                                height={pxToRem(bannerHeight)}
                                alt={overlays[0]?.title ?? ''}
                                loading="eager"
                                objectFit="cover"
                                sizes={getImagesSizes({
                                    desktop: bannerWidth,
                                    mobile: bannerWidth,
                                })}
                            />
                        )}
                    </div>
                }
            >
                {() => (
                    <Carousel
                        getSwiperApi={(swiper) => {
                            setHeaderSwiper(swiper as SwiperCore);
                        }}
                        ref={ref}
                        aria-roledescription={'carousel'}
                        items={images.map((img, idx) => {
                            return (
                                <HeaderImage
                                    key={img.key}
                                    image={{
                                        loading: idx === 0 ? 'eager' : 'lazy',
                                        width: pxToRem(bannerWidth),
                                        height: pxToRem(bannerHeight),
                                        src: img.src,
                                        alt: overlays[currentTile]?.title ?? '',
                                        sizes: getImagesSizes({
                                            desktop: bannerWidth,
                                            mobile: bannerWidth,
                                        }),
                                        layout: 'fill',
                                        objectFit: 'cover',
                                    }}
                                    ctaLink={overlays[currentTile]?.ctaLink}
                                    isExternal={
                                        overlays[currentTile]?.isExternal
                                    }
                                    advertCreative={
                                        overlays[currentTile]?.advertCreative
                                    }
                                />
                            );
                        })}
                        autoplayEnabled={inViewport}
                        autoplay={{
                            disableOnInteraction: false,
                            delay: delay,
                        }}
                        navigation={true}
                        loop={true}
                        effect={'fade'}
                        fadeEffect={{
                            crossFade: true,
                        }}
                        hidePaddles={true}
                        variables={{
                            overlayPadding: pxToRem(0),
                            overlayInitialOpacity: 1,
                            containerWidth: pxToRem(bannerWidth),
                            containerHeight: pxToRem(bannerHeight),
                        }}
                    />
                )}
            </ClientOnly>
            <div className={css(S.ImageOverlayContainer)}>
                <HeaderOverlays
                    overlays={overlays}
                    current={currentTile}
                    loggedIn={loggedIn}
                />
            </div>
            <div className={css(S.OverlayContainerStyle)}>
                <div className={css(S.HeaderBannerControls)}>
                    <div className={css(S.HeaderBannerControlContainer)}>
                        {!isMobile && (
                            <div
                                onClick={() => {
                                    headerSwiper?.slidePrev();
                                }}
                                className={css(S.PaddleBase)}
                            >
                                <div>
                                    <CustomArrowLeft />
                                </div>
                            </div>
                        )}
                        <HeaderProgress
                            animate={animate}
                            delay={delay}
                            total={images.length}
                            current={currentTile}
                        />

                        {!isMobile && (
                            <div
                                onClick={() => {
                                    headerSwiper?.slideNext();
                                }}
                                className={css(S.PaddleBase)}
                            >
                                <div>
                                    <CustomArrowRight />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBanner;
