import type { MetaDescriptor } from '@remix-run/node';
import type { SupportedCountry } from '@archipro-website/localisation';

export const createWebsiteSchema = (
    host: string,
    countryCode: SupportedCountry
): MetaDescriptor[] => {
    return [
        {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            name: 'Archipro ' + countryCode,
            url: host,
            potentialAction: {
                '@type': 'SearchAction',
                target: host + '/search?search={search_term_string}',
                'query-input': 'required name=search_term_string',
            },
        },
    ];
};
