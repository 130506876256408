import type { SaveToDesignBoardProps } from '@modules/design-board/component/SaveToDesignBoard';
import { useRef } from 'react';
import HeaderOverlay from '@modules/home/component/header-module/HeaderOverlay';
import { useHomePageBannerEventHandler } from '../../util/home-banner-tracker-helpers';
import type { AdvertCreative } from '@archipro-website/tracker';

interface HeaderOverlayProps {
    title: string;
    ctaLink: string;
    ctaLabel: string;
    pinType: SaveToDesignBoardProps['itemType'];
    id: number;
    category: string;
    loggedIn: boolean;
    position: number; // start from 1
    advertCreative?: AdvertCreative | null;
}

const HeaderOverlayAdWrapper = ({
    title,
    ctaLink,
    ctaLabel,
    pinType,
    id,
    category,
    loggedIn,
    position,
    advertCreative,
}: HeaderOverlayProps) => {
    const bannerRef = useRef(null);
    const { onClick } = useHomePageBannerEventHandler(
        {
            type: 'FullWidth',
            itemID: id,
            position: position,
        },
        bannerRef
    );

    return (
        <div ref={bannerRef}>
            <HeaderOverlay
                title={title}
                ctaLink={ctaLink}
                ctaLabel={ctaLabel}
                pinType={pinType}
                id={id}
                category={category}
                loggedIn={loggedIn}
                onClick={onClick}
                advertCreative={advertCreative}
            />
        </div>
    );
};

export default HeaderOverlayAdWrapper;
