import { useRef } from 'react';
import type { AdminTileProps, TileImageProps } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { ProfessionalTile, useStyles } from '@archipro-design/aria';
import * as S from './HomeProfessionalTile.style';
import { injectLoadingToImagePropsCollection } from '@modules/home/util/image-to-image-props';
import type { FavouriteItem } from '~/modules/design-board/type/design-board-types';
import type {
    HomeTileTrackerData,
    ProfessionalHomeTileProps,
} from '../../type';
import useHomeTileEventHandler from '../../hook/use-home-tile-handler';
import type { AdvertCreative } from '@modules/adverts/type';
import type { Tracker } from '@archipro-website/tracker';
import { createLinkedComponentParams } from '~/utils/urlHelper';

type HomeProfessionalTileProps = {
    tracker: Tracker;
    isMobile: boolean;
    link: string;
    id: number;
    tile: ProfessionalHomeTileProps;
    favouriteItem: FavouriteItem;
    trackerData?: HomeTileTrackerData;
    ad?: AdvertCreative;
} & Pick<AdminTileProps, 'topActions'>;

const HomeProfessionalTile = ({
    tracker,
    isMobile,
    link,
    tile,
    id,
    favouriteItem,
    trackerData,
    ad,
    topActions,
}: HomeProfessionalTileProps) => {
    const { css } = useStyles();
    const tileRef = useRef(null);
    const { onClick } = useHomeTileEventHandler(trackerData, tileRef, ad);

    const { images, homePinned: _homePinned, ...rest } = tile;
    const title = tile.title;

    const imgPropsCol = injectLoadingToImagePropsCollection(
        images,
        'lazy',
        isMobile,
        'professional',
        title ?? ''
    );

    const tileImgProps: TileImageProps = {
        images: imgPropsCol,
        height: '100%',
        topActions: topActions,
    };

    return (
        <div className={css(S.TileWrapper)}>
            <ProfessionalTile
                ref={tileRef}
                image={imgPropsCol}
                tileImage={tileImgProps}
                {...rest}
                {...createLinkedComponentParams(
                    tracker,
                    null,
                    link,
                    {
                        libraryItemID: trackerData?.itemID,
                        professionalID: trackerData?.professionalID,
                    },
                    onClick,
                    onClick
                )}
                logo={{
                    image: {
                        src:
                            typeof tile.logo === 'object'
                                ? tile.logo.image
                                : tile.logo,
                        alt: `${title} professional logo`,
                        loading: 'lazy',
                    },
                    ...(typeof tile.logo === 'object' && {
                        variables: tile.logo.variables,
                    }),
                }}
                location={null}
                hideFavourite={id === 0}
                services={!isMobile ? rest.services?.slice(0, 3) ?? [] : []}
                size={'dynamic'}
                variant={'04'}
                onFavourite={(e) => {
                    favouriteItem.onClick(e, id);
                }}
                isFavourited={id ? favouriteItem.favourited(id) : false}
                variables={{
                    dynamicSizingWidth: isMobile ? 323 : 811,
                    dynamicSizingGapWidth: 0,
                    tilePadding: pxToRem(0),
                }}
                className={css(S.ProfessionalTile)}
            />
        </div>
    );
};

export default HomeProfessionalTile;
