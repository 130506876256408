import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem, labelClassName } from '@archipro-design/aria';

export const GuestTopNavContainer: StyleRule = ({ theme }) => ({
    height: pxToRem(57),
    width: '100%',
    justifyContent: 'space-between',
    padding: pxArrayToRem([7, 13, 7, 17]),
    alignItems: 'center',
    background: theme.siteVariables.colors.primitive.white,
    [theme.screen.tablet]: {
        height: pxToRem(149),
        padding: pxArrayToRem([34, 40, 52, 51]),
    },
});

export const Logo: StyleRule = () => ({
    height: pxToRem(16),
});

export const RightHandNav: StyleRule = () => ({
    gap: pxToRem(52),
    alignItems: 'center',
});

export const Links: StyleRule = () => ({
    gap: pxToRem(34),
    alignItems: 'center',
});

export const Link: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Medium65,
    fontSize: pxToRem(15),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.siteVariables.colors.charcoal['250'],
    [`&:hover, &:visited, &:active`]: {
        textDecoration: 'none',
        color: theme.siteVariables.colors.charcoal['250'],
    },
});

export const Actions: StyleRule = ({ theme }) => ({
    gap: pxToRem(9),
    alignItems: 'center',
    [theme.screen.max.tablet]: {
        gap: pxToRem(10),
    },
});

export const Primary: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
        lineHeight: pxToRem(15),
        fontSize: pxToRem(15),
    },
    [theme.screen.max.tablet]: {
        [`& .${labelClassName}`]: {
            lineHeight: pxToRem(10),
            fontSize: pxToRem(10),
        },
        padding: pxArrayToRem([16, 26]),
    },
    [theme.screen.tablet]: {
        padding: pxArrayToRem([24, 36]),
        [`& .${labelClassName}`]: {
            lineHeight: pxToRem(13),
        },
    },
});

export const Secondary: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
        lineHeight: pxToRem(15),
        fontSize: pxToRem(15),
    },
    [theme.screen.max.tablet]: {
        [`& .${labelClassName}`]: {
            lineHeight: pxToRem(10),
            fontSize: pxToRem(10),
        },
        padding: pxArrayToRem([16, 26]),
    },
    [theme.screen.tablet]: {
        padding: pxArrayToRem([24, 36]),
        [`& .${labelClassName}`]: {
            lineHeight: pxToRem(13),
        },
    },
});
