import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    labelClassName,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    gridGap: pxToRem(6),
    marginBottom: pxToRem(100),
    padding: pxArrayToRem([0, 18]),
    userSelect: 'none',

    [theme.screen.tablet]: {
        justifyContent: 'space-between',
        gridGap: 'unset',
        marginBottom: pxToRem(0),
        padding: 0,
    },
});

export const BaseMobileStyle: StyleRule = () => ({
    marginBottom: pxToRem(64),
    marginTop: pxToRem(32),
    '> div > div.slider-pagination-target': {
        marginLeft: pxToRem(18),
    },
});

export const PillarContentCard: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([0, 48, 0, 32]),
    [`& .${labelClassName}`]: {
        marginBottom: pxToRem(30),
        marginTop: pxToRem(26),
        fontSize: pxToRem(27.33),
        textTransform: 'uppercase',
        lineHeight: theme.siteVariables.lineHeightDefault,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        ...theme.siteVariables.textStyles.Heading.Roman55,
    },

    [`& .${captionClassName}`]: {
        paddingRight: '2ch',
        lineHeight: theme.siteVariables.lineHeightSmaller,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
    },
});
