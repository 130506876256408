import {
    Grid,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './MediaBlockModule.style';

import MediaBlockAdWrapper from '@modules/home/component/media-block-module/MediaBlockAdWrapper';
import MediaBlockBannerWrapper from './MediaBlockBannerWrapper';
import type { MediaBlockNoClickProps } from './MediaBlock';

interface MediaBlockModuleProps {
    blocks: MediaBlockNoClickProps[];
}

const MediaBlockModule = ({ blocks }: MediaBlockModuleProps) => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    return (
        <div className={css(S.Container)}>
            <Grid
                columns={desktop ? 2 : 1}
                rows={desktop ? 1 : 2}
                className={css(S.GridWrapper)}
            >
                {blocks.map((block, index) => {
                    if (block.advertCreative) {
                        return (
                            <MediaBlockAdWrapper
                                key={block.linkHref}
                                id={block.id}
                                advertCreative={block.advertCreative}
                                image={block.image}
                                title={block.title}
                                subTitle={block.subTitle}
                                linkLabel={block.linkLabel}
                                linkHref={block.linkHref}
                            />
                        );
                    } else {
                        return (
                            <MediaBlockBannerWrapper
                                key={block.linkHref}
                                id={block.id}
                                image={block.image}
                                title={block.title}
                                subTitle={block.subTitle}
                                linkLabel={block.linkLabel}
                                linkHref={block.linkHref}
                                position={index + 1}
                            />
                        );
                    }
                })}
            </Grid>
        </div>
    );
};

export default MediaBlockModule;
