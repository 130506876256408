import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem, captionClassName } from '@archipro-design/aria';
import { hex2rgba } from '~/utils';

export const BaseStyle: StyleRule = ({ theme }) => ({
    [`& .pillar-image`]: {
        display: 'none',
    },
    '&:hover .pillar-image': {
        display: 'flex',
    },

    position: 'relative',

    [theme.screen.tablet]: {
        '& .pillar-text': {
            transition: 'opacity .1s linear .3s',
        },

        '&:hover .pillar-text': {
            opacity: 0,
            transition: 'none',
        },
    },

    [theme.screen.touchScreen]: {
        '&:focus .pillar-image': {
            display: 'flex',
        },

        '&:focus .pillar-text': {
            opacity: 0,
            transition: 'none',
        },
    },
});

export const ImageStyle: StyleRule = ({ theme }) => ({
    width: pxToRem(186),
    height: pxToRem(264.74),
    position: 'relative',

    [theme.screen.tablet]: {
        width: pxToRem(348),
        height: pxToRem(500),
    },
});

export const TopStyle: StyleRule = ({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: theme.siteVariables.lineHeightSmall,
    letterSpacing: theme.siteVariables.letterSpacingSmall,
    ...theme.siteVariables.textStyles.Text.Roman55,
    gap: '2ch',
});

export const LinksOverlayStyle: StyleRule = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    [theme.screen.tablet]: {
        position: 'relative',
        height: 0,
        width: 'auto',
        left: 'unset',
        top: 'unset',
    },
});

export const ContentWrapStyle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Display.Roman55,
    letterSpacing: '0.1em',
    padding: pxArrayToRem([32, 18]),
    justifyContent: 'space-between',
    background: hex2rgba(theme.siteVariables.colors.primitive.black, 0.4),
    position: `absolute`,
    height: pxToRem(500),
    bottom: pxToRem(0),
    width: '100%',
    opacity: 0,
    transition: '0.15s linear',

    [`& > div`]: {
        transform: 'translate(0, 10px)',
        transition: 'transform .15s linear',
    },

    [theme.screen.tablet]: {
        '&:hover': {
            transition: '0.15s linear',
            opacity: '1',

            [`& > div`]: {
                transform: 'translate(0, 0)',
                transition: 'transform .15s linear',
            },
        },
    },
});

export const PillarText: StyleRule<{ lightMode?: boolean }> = ({
    theme,
    lightMode,
}) => ({
    [`&.${captionClassName}`]: {
        position: 'absolute',
        top: pxToRem(0),
        left: pxToRem(0),
        padding: pxArrayToRem([10, 10, 0, 8]),
        width: '100%',
        fontSize: pxToRem(14),
        lineHeight: theme.siteVariables.lineHeightSmall,
        color: lightMode
            ? theme.siteVariables.colors.primitive.black
            : theme.siteVariables.colors.primitive.white,
        textTransform: 'uppercase',
        ...theme.siteVariables.textStyles.Heading.Roman55,

        [theme.screen.tablet]: {
            fontSize: pxToRem(20),
            padding: pxArrayToRem([31, 18, 0]),
        },
    },
});
