import {
    pxToRem,
    pxArrayToRem,
    buttonClassName,
    labelClassName,
} from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const BaseStyle: StyleRule = () => ({});

export const LogoutButton: StyleRule = ({ theme }) => ({
    cursor: 'pointer',
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: pxToRem(14),
    lineHeight: theme.siteVariables.lineHeightSmall,
    marginTop: pxToRem(18),
    alignItems: 'center',
});

export const LoginButton: StyleRule = () => ({
    cursor: 'pointer',

    marginTop: pxToRem(43),

    [`&.${buttonClassName}`]: {
        padding: pxArrayToRem([11.5, 23.5]),

        [`& .${labelClassName}`]: {
            fontSize: pxToRem(13),
        },

        [`& .${svgIconClassName}`]: {
            width: pxToRem(24),
            height: pxToRem(24),
        },
    },
});

export const ItemsBox: StyleRule = () => ({
    maxWidth: pxToRem(225),
});

export const Item: StyleRule = () => ({
    cursor: 'default',
    marginBottom: pxToRem(1),
    width: 'fit-content',
});
