import { useState } from 'react';
import {
    useStyles,
    useAppDisplayModeContext,
    Grid,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './HomeModule.style';
import ProfessionalsModule from '@modules/home/component/professionals-module/ProfessionalsModule';
import type {
    HomeArticleTileProps,
    HomeTileViewModel,
    ProfessionalHomeTileProps,
} from '@modules/home/type';
import ProductsModule from '@modules/home/component/products-module/ProductsModule';
import type { ProductHomeTileProps } from '@modules/home/util/product-home-tile-view-model.server';
import type { ProjectHomeTileProps } from '@modules/home/util/project-home-tile-view-model.server';
import type { BannerTileProps } from '@modules/home/component/header-module/HeaderModule';
import HeaderModule from '@modules/home/component/header-module/HeaderModule';
import PillarsGroup from '@modules/home/component/pillar-module/PillarsGroup';
import type { User } from '~/server/types';
import LoginSignUpModal from '@modules/user/component/login-signup-modal/LoginSignUpModal';
import type { AdvertCreative } from '@modules/adverts/type';
import MediaBlockModule from '@modules/home/component/media-block-module/MediaBlockModule';
import InspirationModule, {
    InspirationProject,
} from '@modules/home/component/inspiration-module/InspirationModule';
import WhyArchiProModule from '@modules/home/component/archipro-for-module/WhyArchiProModule';
import ListYourBusinessModule from '~/modules/home/component/list-your-business-module/ListYourBusinessModule';
import FeaturedProfessionals from '@modules/home/component/professionals-module/FeaturedProfessionals';
import type { FeaturedProfessionalsProps } from '@modules/home/component/professionals-module/FeaturedProfessionals';
import AdPlacementModule from '@modules/home/component/ad-placement-module/AdPlacementModule';
import type { AdPlacementModuleProps } from '@modules/home/component/ad-placement-module/AdPlacementModule';
import { useAppData } from '@modules/root';
import ProjectsModule from '../projects-module/ProjectsModule';
import type { MediaBlockNoClickProps } from '@modules/home/component/media-block-module/MediaBlock';

interface HomeModuleProps {
    user: User;
    bannerTiles: BannerTileProps[];
    professionalTiles: HomeTileViewModel<ProfessionalHomeTileProps>[];
    productTiles: HomeTileViewModel<ProductHomeTileProps>[];
    firstProjectOfTheMonth?: HomeTileViewModel<ProjectHomeTileProps>;
    projectsOfTheMonth: HomeTileViewModel<ProjectHomeTileProps>[];
    articleTiles: HomeArticleTileProps[];
    mediaBlocks?: MediaBlockNoClickProps[];
    logoWall?: FeaturedProfessionalsProps;
    adPlacements?: AdPlacementModuleProps[];
    featuredProjectTiles: HomeTileViewModel<ProjectHomeTileProps>[];
    homeFeatureAds: AdvertCreative[];
}

const HomeModule = ({
    user,
    bannerTiles,
    professionalTiles,
    productTiles,
    firstProjectOfTheMonth,
    projectsOfTheMonth,
    articleTiles,
    mediaBlocks,
    logoWall,
    adPlacements,
    featuredProjectTiles,
    homeFeatureAds,
}: HomeModuleProps) => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    const [showSignUp, setShowSignUp] = useState(false);
    const { megamenu } = useAppData();

    const onSignUpAction = () => {
        setShowSignUp(true);
    };

    const productPerSlice = !desktop ? 4 : 12;

    return (
        <Grid columns={'100%'} className={css(S.BaseStyle)}>
            <HeaderModule
                bannerTiles={bannerTiles}
                isMobile={!desktop}
                onSignupClick={onSignUpAction}
                user={user}
            />

            <PillarsGroup isMobile={!desktop} />

            {firstProjectOfTheMonth && (
                <div className={css(S.FirstProjectOfTheMonthContainer)}>
                    <InspirationProject
                        isMobile={!desktop}
                        {...firstProjectOfTheMonth}
                    />
                </div>
            )}

            <ProductsModule
                isMobile={!desktop}
                productTiles={productTiles.slice(0, productPerSlice)}
                sectionTitle={`this week’s highlights to Bring Your building Vision to Life`}
                ads={homeFeatureAds}
                categoryNavItems={
                    desktop
                        ? megamenu?.product.subNavItems ?? undefined
                        : undefined
                }
            />

            <ProfessionalsModule professionalTiles={professionalTiles} />

            {mediaBlocks?.length && <MediaBlockModule blocks={mediaBlocks} />}

            {logoWall && desktop && <FeaturedProfessionals {...logoWall} />}

            <ProductsModule
                isMobile={!desktop}
                productTiles={productTiles.slice(
                    productPerSlice,
                    productPerSlice + productPerSlice
                )}
                categoryNavItems={megamenu?.product.subNavItems ?? undefined}
                ads={[]}
            />

            <ProjectsModule projectTiles={featuredProjectTiles} />

            <InspirationModule
                isMobile={!desktop}
                projectTiles={projectsOfTheMonth}
                articleTiles={articleTiles}
            />

            <Grid
                columns={'100%'}
                styles={{
                    gridGap: pxToRem(!desktop ? 105 : 100),
                }}
            >
                <WhyArchiProModule />
                {adPlacements?.[0] && (
                    <AdPlacementModule {...adPlacements[0]} />
                )}
                <ListYourBusinessModule />
            </Grid>

            <LoginSignUpModal
                login={{
                    open: showSignUp,
                    onCancel: () => setShowSignUp(false),
                    onConfirm: () => setShowSignUp(false),
                    onAuthSuccess: () => setShowSignUp(false),
                    flow: null,
                    authSource: 'homeModule',
                }}
                forgotPassword={{
                    open: false,
                    onCancel: () => setShowSignUp(false),
                }}
            />
        </Grid>
    );
};

export default HomeModule;
