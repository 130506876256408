import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    gridClassName,
    pxArrayToRem,
    pxToRem,
    SubheaderClassName,
} from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([0, 18, 0]),
    margin: `0 auto ${pxToRem(105)}`,

    [theme.screen.tablet]: {
        maxWidth: pxToRem(1380),
        width: '100%',
        margin: `0 auto`,

        [`& .${gridClassName}`]: {
            gridGap: pxToRem(24),
        },
    },
});

export const Grid: StyleRule = ({ theme }) => ({
    rowGap: pxToRem(14),
    [theme.screen.tablet]: {
        rowGap: 0,
        justifyContent: 'flex-start',
    },
});

export const SectionHeader: StyleRule = ({ theme }) => ({
    [`&.${SubheaderClassName}`]: {
        fontSize: theme.siteVariables.fontSizes['label03'],
        lineHeight: theme.siteVariables.lineHeightSmaller,
        padding: pxArrayToRem([10, 0]),
        margin: pxArrayToRem([0, 0, 16]),
    },

    [theme.screen.tablet]: {
        [`&.${SubheaderClassName}`]: {
            margin: pxArrayToRem([0, 0, 29]),
            padding: 0,
            fontSize: theme.siteVariables.fontSizes['subheading03-alt'],
            lineHeight: theme.siteVariables.lineHeightSmaller,
            letterSpacing: theme.siteVariables.letterSpacingSmall,
        },
    },
});

export const Block: StyleRule = ({ theme }) => ({
    border: `1px solid ${theme.siteVariables.colors.white['300']}`,
    padding: pxArrayToRem([23, 25, 21]),

    [`& .${captionClassName}`]: {
        lineHeight: theme.siteVariables.lineHeightSmall,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
    },

    [theme.screen.tablet]: {
        padding: pxArrayToRem([31, 30]),
    },
});

export const Icon: StyleRule = () => ({
    marginBottom: pxToRem(13),
});

export const BlockTitle: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(12),
    [theme.screen.tablet]: {
        marginBottom: pxToRem(18),
    },
});

export const BlockContent: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(14),
    [`&.${captionClassName}`]: {
        paddingRight: '4ch',
    },
    [theme.screen.tablet]: {
        marginBottom: pxToRem(16),
        [`&.${captionClassName}`]: {
            paddingRight: '2ch',
        },
    },
});

export const BlockLink: StyleRule = ({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: theme.siteVariables.lineHeightSmall,
    letterSpacing: theme.siteVariables.letterSpacingSmall,
    color: theme.siteVariables.colorScheme?.default?.foreground,
    [`&:link`]: {
        textDecoration: 'underline',
    },
});
