import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const Container: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 18, 60]),
    [theme.screen.tablet]: {
        maxWidth: pxToRem(1440),
        width: '100%',
        margin: `0 auto`,
    },
});

export const GridWrapper: StyleRule = ({ theme }) => ({
    gridGap: pxToRem(18),
    [theme.screen.tablet]: {
        gridGap: pxToRem(20),
    },
});
