import type { StyleRule } from '@archipro-design/aria';
import { tileImageSlotClassNames } from '@archipro-design/aria';

export const HomeProductTile: StyleRule<{ isMultiplied?: boolean }> = ({
    theme,
    isMultiplied,
}) => ({
    ...(isMultiplied && {
        [`& .${tileImageSlotClassNames.imageContainer}`]: {
            backgroundColor: theme.siteVariables.colors.white['200A'],

            [`& img`]: {
                mixBlendMode: 'multiply',
            },
        },
    }),
});

export const ShoppingAvailableWrap: StyleRule = () => ({
    width: 'fit-content',
    height: 'fit-content',
});
