import type { ButtonProps } from '@archipro-design/aria';
import {
    Flex,
    Button,
    useStyles,
    pxArrayToRem,
    pxToRem,
    useTheme,
} from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import * as S from './CategoryNav.style';

import { CustomArrowRight } from '@archipro-design/icons';
import type { NavigationItem } from '@archipro-website/top-navigation';

export interface CategoryNavItem extends NavigationItem {
    id: string;
    variables?: ButtonProps['variables'];
}
interface CategoryNavProps {
    items: CategoryNavItem[];
    groupCTA?: {
        title: string;
        link: string;
    };
}

const CategoryNav = ({ items, groupCTA }: CategoryNavProps) => {
    const { css } = useStyles();
    const theme = useTheme();

    return (
        <div className={css(S.Container)}>
            <Flex vAlign="center" space={'between'}>
                <Flex className={css(S.NavContainer)} vAlign="center">
                    {items?.map((item) => (
                        <Button
                            key={item.link}
                            as={Link}
                            prefetch="intent"
                            to={item.link}
                            variables={{
                                buttonRadius: pxToRem(50),
                                primaryButtonBackgroundColorNormal:
                                    theme.siteVariables.colors.charcoal['000'],
                                primaryButtonBackgroundColorHover:
                                    theme.siteVariables.colors.gold['000'],
                                ...item.variables,
                            }}
                        >
                            {item.name}
                        </Button>
                    ))}
                </Flex>
                {groupCTA && (
                    <Button
                        className={css(S.CTAButton)}
                        as={Link}
                        prefetch="intent"
                        to={groupCTA.link}
                        variant={'contained'}
                        outlined
                        size={16}
                        iconAfter={<CustomArrowRight />}
                        variables={{
                            buttonPadding: pxArrayToRem([10.5, 17.5, 10.5]),
                            iconPadding: 0,
                            buttonSize16IconXMargin: pxToRem(8),
                        }}
                    >
                        {groupCTA.title}
                    </Button>
                )}
            </Flex>
        </div>
    );
};

export default CategoryNav;
