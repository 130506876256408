import { useStyles } from '@archipro-design/aria';
import type { ReactElement } from 'react';
import * as S from './HeaderProgress.style';

interface HeaderProgressProps {
    delay: number;
    total: number;
    current: number;
    animate: boolean;
}

const HeaderProgress = ({
    total,
    current,
    delay,
    animate,
}: HeaderProgressProps) => {
    const { css } = useStyles({
        delay: delay,
    });

    const ProgressBar = (bar: number, current: number): ReactElement => {
        let barState;
        let progress;

        if (current > bar) {
            barState = 'fill';
            progress = '0';
        } else if (current < bar) {
            barState = 'empty';
            progress = '0';
        } else {
            if (!animate) {
                barState = 'empty';
                progress = '0';
            } else {
                barState = 'animate';
                progress = '100';
            }
        }

        return (
            <div className={`${css(S.MeterStyle)} ${barState}`} key={bar}>
                <div
                    className={css(S.BarStyle)}
                    style={{
                        width: `${progress}%`,
                    }}
                />
            </div>
        );
    };

    return (
        <div className={css(S.BaseStyle)}>
            {[...Array(total)].map((_, i) => ProgressBar(i, current))}
        </div>
    );
};

export default HeaderProgress;
