import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { labelClassName } from '@fluentui/react-northstar';

export const ProductModuleStyle: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(100),
    [theme.screen.min.tablet]: {
        marginBottom: pxToRem(0),
    },
});

export const CategoryNavContainer: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(25),
    [theme.screen.tablet]: {
        marginBottom: 0,
    },
});

export const ContentWrapStyle: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        marginBottom: pxToRem(160),
    },
    marginBottom: pxToRem(50),
});

export const ButtonWrapStyle: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        paddingBottom: pxToRem(180),
        paddingTop: pxToRem(130),
    },
    paddingBottom: pxToRem(80),
    paddingTop: pxToRem(40),
});

export const ButtonWrapMobileStyle: StyleRule = () => ({
    padding: pxArrayToRem([0, 18]),
    marginTop: pxToRem(60),
});

export const ButtonMobileStyle: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
    },
});

export const ButtonWrapLoggedInStyle: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        paddingBottom: pxToRem(125),
        paddingTop: pxToRem(130),
    },
    paddingBottom: pxToRem(80),
    paddingTop: pxToRem(40),
});
