import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    margin: `0 auto`,

    [theme.screen.tablet]: {
        gridGap: pxToRem(220),
        padding: pxArrayToRem([0, 50, 166]),
    },

    [theme.screen.clamped]: {
        padding: pxArrayToRem([0, 0, 166]),
    },
});

export const FirstProjectOfTheMonthContainer: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(100),
    [theme.screen.tablet]: {
        marginBottom: pxToRem(0),
    },
});

export const ContentStyle: StyleRule = () => ({
    width: '100%',
    margin: `0 auto`,
});
