import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const BaseStyle: StyleRule<{ disableAnimation?: boolean }> = ({
    theme,
    disableAnimation = false,
}) => ({
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),

    [`& .${svgIconClassName}`]: {
        display: 'none',
    },

    ...(!disableAnimation && {
        cursor: 'pointer',
        [`& .${svgIconClassName}`]: {
            display: 'block',
            marginLeft: pxToRem(8),
            transform: `translate(-8px, 0)`,
            opacity: 0,
            transitionProperty: 'transform, opacity',
            transitionDuration: '.3s',
            width: pxToRem(14),
            height: pxToRem(14),
        },

        '&:hover': {
            borderBottomColor: theme.siteVariables.colors.charcoal[250],
            color: theme.siteVariables.colors.charcoal[250],

            [`& .${svgIconClassName}`]: {
                transform: `translate(0, 0)`,
                opacity: 1,
            },
        },
    }),
});

export const RowContentStyle: StyleRule = () => ({
    justifyContent: 'space-between',
});
