import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { BANNER_HEIGHT, BANNER_HEIGHT_MOBILE } from '@modules/home/const';

export const BaseStyle: StyleRule = ({ theme }) => ({
    display: 'block', // Incase "as" type is <a/> which is inline
    textDecoration: 'none',

    background: theme.siteVariables.colors.white[100],
    position: 'relative',
});

export const ImageWrapStyle: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        height: pxToRem(BANNER_HEIGHT),
    },
    height: pxToRem(BANNER_HEIGHT_MOBILE),
    position: 'relative',
    background: '#F6F6F6',
    [`& img`]: {
        mixBlendMode: 'multiply',
    },
});
