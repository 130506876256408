import { useStyles } from '@archipro-design/aria';
import * as S from './HomeSliderTiles.style';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';

SwiperCore.use([Navigation, Pagination, Mousewheel]);

interface ProfessionalTilesPros {
    tiles: JSX.Element[];
}

const HomeSliderTiles = ({ tiles }: ProfessionalTilesPros) => {
    const { css } = useStyles();

    return (
        <div className={css(S.CarouselSection)}>
            <Swiper
                loop={false}
                slidesPerView={'auto'}
                spaceBetween={0}
                direction={'horizontal'}
                mousewheel={{
                    forceToAxis: true,
                    thresholdTime: 50,
                    thresholdDelta: 50,
                }}
            >
                {tiles.map((tile) => {
                    return (
                        <SwiperSlide key={tile.key} className={css(S.Slide)}>
                            {tile}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default HomeSliderTiles;
