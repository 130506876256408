import { useRef } from 'react';
import type { AdminTileProps} from '@archipro-design/aria';
import { ProjectTile, useStyles, pxToRem } from '@archipro-design/aria';
import * as S from './HomeProjectTile.style';
import { injectLoadingToImagePropsCollection } from '@modules/home/util/image-to-image-props';
import type { ProjectHomeTileProps } from '@modules/home/util/project-home-tile-view-model.server';
import type { FavouriteItem } from '~/modules/design-board/type/design-board-types';
import type { HomeTileTrackerData } from '../../type';
import useHomeTileEventHandler from '../../hook/use-home-tile-handler';
import type { AdvertCreative } from '@modules/adverts/type';
import type { Tracker } from '@archipro-website/tracker';
import { createLinkedComponentParams } from '~/utils/urlHelper';

type HomeProjectTileProps = {
    tracker: Tracker;
    isMobile: boolean;
    link: string;
    id: number;
    tile: ProjectHomeTileProps;
    favouriteItem: FavouriteItem;
    onProfessionalClick: (url: string) => void;
    trackerData?: HomeTileTrackerData;
    ad?: AdvertCreative;
} & Pick<AdminTileProps, 'topActions'>;

const HomeProjectTile = ({
    tracker,
    isMobile,
    link,
    id,
    tile,
    favouriteItem,
    trackerData,
    ad,
    topActions,
}: HomeProjectTileProps) => {
    const { css } = useStyles();
    const tileRef = useRef(null);
    const { onClick } = useHomeTileEventHandler(trackerData, tileRef, ad);

    const imageSize = {
        height: pxToRem(isMobile ? 184 : 464),
        width: pxToRem(isMobile ? 323 : 811),
    };

    const imgPropsCol = injectLoadingToImagePropsCollection(
        tile.image[0] ? [tile.image[0]] : [],
        'lazy',
        isMobile,
        'project',
        tile.projectName
    );

    return (
        <div className={css(S.TileWrapper)}>
            <ProjectTile
                ref={tileRef}
                image={imgPropsCol}
                tileImage={{
                    images: imgPropsCol,
                    height: imageSize.height,
                    width: imageSize.width,
                    variables: {
                        height: imageSize.height,
                        width: imageSize.width,
                    },
                    topActions: topActions,
                }}
                projectName={tile.projectName}
                professionalName={`By ${tile.professionalName}`}
                variant={isMobile ? '02' : undefined}
                size={isMobile ? 'large' : 'large'}
                onFavourite={(e) => {
                    favouriteItem.onClick(e, id);
                }}
                hideFavourite={id === 0}
                isFavourited={id ? favouriteItem.favourited(id) : false}
                {...createLinkedComponentParams(
                    tracker,
                    null,
                    link,
                    {
                        libraryItemID: trackerData?.itemID,
                        professionalID: trackerData?.professionalID,
                    },
                    onClick,
                    onClick
                )}
                variables={{
                    tilePadding: 0,
                }}
                newDesignEnabled={true}
            />
        </div>
    );
};

export default HomeProjectTile;
