import { pxArrayToRem, pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([0, 18, 0]),
    [theme.screen.tablet]: {
        padding: 0,
        position: 'relative',
        margin: '0 auto',
        maxWidth: pxToRem(1030),
    },
});

export const Container: StyleRule = ({ theme }) => ({
    display: 'block',
    position: 'relative',
    width: pxToRem(378),
    height: pxToRem(97),

    [theme.screen.tablet]: {
        cursor: 'pointer',
        width: pxToRem(1030),
        height: pxToRem(260),
    },
});
