import {
    Button,
    Divider,
    Flex,
    pxToRem,
    useTheme,
} from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import * as S from './HeaderMenu.style';
import HeaderMenuItem from '@modules/home/component/header-module/HeaderMenuItem';
import { useLogout, useUser } from '~/modules/user';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import { useBoolean } from 'ahooks';
import type { ElementType } from 'react';
import { useCallback } from 'react';
import { CustomArrowRight } from '@archipro-design/icons';
import { Link } from '@remix-run/react';
import { hex2rgba } from '~/utils';
import type { Tracker } from '@archipro-website/tracker';
import { useTracker } from '@archipro-website/tracker';

interface ShorthandConfig {
    as?: ElementType;
}
type RenderAnchorTag = (link: string, label: string) => ShorthandConfig;

interface HeaderMenuProps {
    loggedIn?: boolean;
}

const menuItems = [
    { label: 'SHOPPING CART', link: '/shopping-cart' },
    { label: 'INBOX', link: '/member/inbox' },
    { label: 'ORDERS', link: '/member/orders' },
    { label: 'DESIGN BOARDS', link: '/member/design-boards' },
    { label: 'NOTIFICATIONS', link: '/member/notifications' },
    { label: 'SETTINGS', link: '/member/settings' },
];

const HeaderMenu = ({ loggedIn }: HeaderMenuProps) => {
    const { css } = useStyles();
    const { onLogoutClick } = useLogout();

    const user = useUser();

    const theme = useTheme();

    const tracker = useTracker();

    const [showSignIn, setShowSignIn] = useBoolean();

    const renderAnchorCallback: RenderAnchorTag = useCallback(
        (link: string, label: string) => {
            const onClick = () => {
                trackHeaderMenuClickEvent(tracker, label);
            };

            if (!loggedIn) {
                const adPageList = ['/sell-on-archipro', '/become-a-pro'];
                if (adPageList.includes(link)) {
                    return {
                        as: 'a',
                        href: link,
                        onClick: onClick,
                    };
                }
                return {
                    as: 'div',
                };
            }

            if (link === '/member/design-boards') {
                return {
                    as: Link,
                    to: link,
                    onClick: onClick,
                };
            }

            return {
                as: 'a',
                href: link,
                onClick: onClick,
            };
        },
        [loggedIn, tracker]
    );

    const ecommerceActiveFlag = user.FeatureFlags.find(
        (f) => f.Name === 'ecommerce_active'
    );

    const shouldShowShoppingCart = !!ecommerceActiveFlag?.Enabled;

    const onLoginClick = () => {
        setShowSignIn.setTrue();
        tracker.log('accountLogin', {
            url: new URL(window.location.href),
            data: {
                step: 1,
            },
        });
    };

    return (
        <div className={css(S.BaseStyle)}>
            <Flex className={css(S.ItemsBox)} column>
                {menuItems
                    .filter((i) => {
                        switch (i.link) {
                            case '/shopping-cart':
                                return shouldShowShoppingCart;
                            case '/member/orders':
                                return !!ecommerceActiveFlag?.Enabled;
                            default:
                                return true;
                        }
                    })
                    .map((item) => {
                        // remove shopping cart if not logged in
                        if (!loggedIn && item.link === '/shopping-cart')
                            return null;
                        return (
                            <Flex
                                key={item.link}
                                className={css(S.Item)}
                                {...renderAnchorCallback(item.link, item.label)}
                            >
                                <HeaderMenuItem
                                    {...item}
                                    disableAnimation={!loggedIn}
                                />
                            </Flex>
                        );
                    })}
                <Divider
                    size={1}
                    variables={{
                        dividerPadding: pxToRem(8),
                        dividerPrimaryColor: hex2rgba('#000000', 0.1),
                    }}
                />
            </Flex>

            <Flex className={css(S.ItemsBox)} column>
                <Flex
                    className={css(S.Item)}
                    {...renderAnchorCallback('/become-a-pro', 'Become a Pro')}
                >
                    <HeaderMenuItem label="Become a Pro" />
                </Flex>
                <Flex
                    className={css(S.Item)}
                    {...renderAnchorCallback(
                        '/sell-on-archipro',
                        'Sell on ArchiPro'
                    )}
                >
                    <HeaderMenuItem label="Sell on ArchiPro" />
                </Flex>
                <Divider
                    size={1}
                    variables={{
                        dividerPadding: pxToRem(8),
                        dividerPrimaryColor: hex2rgba('#000000', 0.1),
                    }}
                />
            </Flex>
            {loggedIn && (
                <Flex
                    onClick={() => {
                        trackHeaderMenuClickEvent(tracker, 'Log out');
                        onLogoutClick();
                    }}
                    className={css(S.LogoutButton)}
                    column={false}
                >
                    <div>Log out</div>
                </Flex>
            )}
            {!loggedIn && (
                <Button
                    iconAfter={<CustomArrowRight />}
                    onClick={() => {
                        trackHeaderMenuClickEvent(tracker, 'SIGN UP / LOG IN');
                        onLoginClick();
                    }}
                    className={css(S.LoginButton)}
                    size={14}
                    variables={{
                        primaryButtonBackgroundColorNormal:
                            theme.siteVariables.colors.primitive.black,
                        primaryButtonBackgroundColorHover:
                            theme.siteVariables.colors.charcoal['250'],
                        iconPadding: 0,
                    }}
                >
                    SIGN UP / LOG IN
                </Button>
            )}
            <LoginSignUpModal
                login={{
                    open: showSignIn,
                    onCancel: setShowSignIn.setFalse,
                    onConfirm: setShowSignIn.setFalse,
                    onAuthSuccess: setShowSignIn.setFalse,
                    flow: null,
                    authSource: 'headerMenu',
                }}
                forgotPassword={{
                    open: showSignIn,
                    onCancel: setShowSignIn.setFalse,
                }}
            />
        </div>
    );
};

const trackHeaderMenuClickEvent = (tracker: Tracker, label: string) => {
    tracker.log('HomepagePersonalNavClick', {
        url: new URL(window.location.href),
        targetTracker: 'archiproTrackerV2',
        data: {
            ExtraData: label,
        },
    });
};

export default HeaderMenu;
