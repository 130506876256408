import type { StyleRule } from '@archipro-design/aria';
import { labelClassName, pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const ProfessionalModuleStyle: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(100),
    [theme.screen.laptop]: {
        marginBottom: 0,
    },
});

export const ButtonWrapMobileStyle: StyleRule = () => ({
    padding: pxArrayToRem([0, 18]),
    marginTop: pxToRem(40),
});

export const ButtonMobileStyle: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
    },
});
