import { Image, useStyles, Flex } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import * as S from './CustomLogoTile.style';
import { getImagesSizes } from '~/utils/image-sizes-utils';

export interface CustomLogoTileProps {
    image: string;
    imageAlt: string;
    linkHref: string;
}

const CustomLogoTile = ({ image, imageAlt, linkHref }: CustomLogoTileProps) => {
    const { css } = useStyles();

    return (
        <Flex className={css(S.Card)} as={Link} to={linkHref} prefetch="intent">
            <div className={css(S.ImageWrap)}>
                <Image
                    src={image}
                    width={150}
                    alt={imageAlt}
                    height={150}
                    layout={'responsive'}
                    sizes={getImagesSizes(150)}
                />
            </div>
        </Flex>
    );
};

export default CustomLogoTile;
