import { useRef } from 'react';
import type { MediaBlockNoClickProps } from '@modules/home/component/media-block-module/MediaBlock';
import MediaBlock from '@modules/home/component/media-block-module/MediaBlock';
import { useAdEventHandler } from '@archipro-website/tracker';

const MediaBlockAdWrapper = (block: MediaBlockNoClickProps) => {
    const bannerRef = useRef(null);
    const { onClick } = useAdEventHandler(block.advertCreative, bannerRef);

    return (
        <div ref={bannerRef}>
            <MediaBlock
                key={block.linkHref}
                onClick={(adEventType) => {
                    onClick(adEventType);
                }}
                {...block}
            />
        </div>
    );
};

export default MediaBlockAdWrapper;
