import { useStyles, Flex } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import * as S from './PillarLink.style';
import { ArrowRightLine } from '@archipro-design/icons';
interface PillarLinkProps {
    link: string;
    label: string;
}

const PillarLink = ({ link, label }: PillarLinkProps) => {
    const { css } = useStyles();

    return (
        <Link to={link} className={css(S.BaseStyle)} prefetch="intent">
            <Flex className={css(S.WrapStyle)} column={false} vAlign="center">
                <div className={css(S.LabelStyle)}>{label}</div>
                <Flex
                    className={css(S.ArrowStyle) + ' pillar-arrow'}
                    vAlign="center"
                >
                    <ArrowRightLine />
                </Flex>
            </Flex>
        </Link>
    );
};

export default PillarLink;
