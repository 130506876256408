import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    pxArrayToRem,
    pxToRem,
    SubheaderClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const BaseStyle: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([80, 18, 0]),
    [theme.screen.tablet]: {
        padding: 0,
        maxWidth: pxToRem(1380),
        width: '100%',
        margin: `0 auto ${pxToRem(30)}`,
    },
});

export const SectionHeader: StyleRule = ({ theme }) => ({
    [`&.${SubheaderClassName}`]: {
        fontSize: theme.siteVariables.fontSizes['label03'],
        lineHeight: theme.siteVariables.lineHeightSmaller,
        margin: pxArrayToRem([0, 0, 26]),
    },

    [theme.screen.tablet]: {
        [`&.${SubheaderClassName}`]: {
            margin: pxArrayToRem([0, 0, 38]),
            fontSize: theme.siteVariables.fontSizes['subheading03-alt'],
            letterSpacing: theme.siteVariables.letterSpacingSmall,
        },
    },
});

export const Grid: StyleRule = ({ theme }) => ({
    rowGap: pxToRem(14),
    [theme.screen.tablet]: {
        rowGap: 0,
        columnGap: pxToRem(203),
        justifyContent: 'flex-start',
    },
});

export const BlockLink: StyleRule = () => ({
    marginTop: pxToRem(10),
    [`&.${captionClassName}`]: { textDecoration: 'underline' },
});

export const Block: StyleRule = ({ theme }) => ({
    width: '100%',
    padding: pxArrayToRem([23, 25, 29]),
    border: `1px solid #EDEDED`,

    [`& .${captionClassName}`]: {
        lineHeight: theme.siteVariables.lineHeightSmall,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
    },

    [theme.screen.tablet]: {
        width: pxToRem(270),
        padding: 0,
        border: 'none',
        marginBottom: pxToRem(4),
    },
});

export const Content: StyleRule = ({ theme }) => ({
    paddingRight: '4ch',
    [theme.screen.tablet]: {
        paddingRight: 0,
    },
});

export const Icon: StyleRule = () => ({
    marginBottom: pxToRem(16),
    [`& .${svgIconClassName}`]: {
        width: pxToRem(24),
        height: pxToRem(25.79),
    },
});
