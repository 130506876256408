import type { MetaFunction } from '@remix-run/node';
import type { Organization, PostalAddress } from 'schema-dts';
import invariant from 'tiny-invariant';
import type { SupportedCountry } from '@archipro-website/localisation';
import type { loader as rootLoader } from '~/root';
import { getMetaBaseUrl } from '../util/create-meta-base-url';
import type { loader } from '~/routes/_app._index';
import type { loader as appLoader } from '~/routes/_app';
import { getRouteData } from '~/modules/root/util/get-route-data';

const ORGANIZATION_ADDRESS: Record<SupportedCountry, PostalAddress> = {
    NZ: {
        '@type': 'PostalAddress',
        postalCode: '1052',
        streetAddress:
            'Level 3 Textiles Centre, 117-125 Saint Georges Bay Road',
        addressRegion: 'AUK',
        addressCountry: 'NZ',
        addressLocality: 'Parnell',
    },
    AU: {
        '@type': 'PostalAddress',
        addressCountry: 'Australia',
        streetAddress: '346 Kent Street',
        addressLocality: 'Sydney',
        addressRegion: 'NSW',
        postalCode: '2000',
    },
};

const ORGANIZATION_SOCIALS: Record<SupportedCountry, string[]> = {
    NZ: [
        'https://www.facebook.com/archipro/',
        'https://www.instagram.com/archipro_',
        'https://nz.linkedin.com/company/archipro/',
        'https://www.pinterest.nz/ArchiPro/',
        'https://www.youtube.com/@ArchiProGlobal',
        'https://www.tiktok.com/@archipro_global',
    ],
    AU: [
        'https://www.facebook.com/archipro.australia/',
        'https://www.instagram.com/archipro_au/',
        'https://au.linkedin.com/company/archipro',
        'https://www.pinterest.com.au/ArchiPro/',
        'https://www.youtube.com/@ArchiProGlobal',
        'https://www.tiktok.com/@archipro_global',
    ],
};

export const createOrganisationSchema: MetaFunction<
    typeof loader,
    { root: typeof rootLoader; 'routes/_app': typeof appLoader }
> = (props) => {
    const { rootData } = getRouteData(props.matches);
    const baseUrl = getMetaBaseUrl(props);
    const country = rootData?.localisation.country;

    invariant(country, 'index.ts meta(): country is required');

    const organisationSchema: Organization = {
        '@type': 'Organization',
        name: 'ArchiPro',
        description:
            'ArchiPro is a comprehensive and interactive online directory for building, design and architecture. A technology platform that connects the dots between the country’s projects, products, professionals and homeowners via a ground-breaking, user-friendly website that is as functional as it is inspirational. The platform now showcases a directory of 14,000 building and interior products, 5,000 completed architectural projects and 750 design and trade professionals, 127,000 inspirational images and over 300 educational articles and interviews with some of the country’s leading suppliers and professionals.',
        url: baseUrl,
        logo:
            baseUrl +
            '/_resources/themes/archipro2/images/favicon/apple-touch-icon-152x152.png',
        address: ORGANIZATION_ADDRESS[country],
        ...(country === 'NZ' && { telephone: '+6493020191' }),
        founder: {
            '@type': 'Person',
            familyName: 'Zeqiri',
            givenName: 'Milot',
            jobTitle: 'Founder & CEO',
            worksFor: 'ArchiPro',
        },
        foundingDate: 'September 2014',
        foundingLocation: 'Auckland, NZ',
        legalName: 'ArchiPro Limited',
        numberOfEmployees: { '@type': 'QuantitativeValue', value: 120 },
        sameAs: ORGANIZATION_SOCIALS[country],
    };

    return [organisationSchema];
};
