import { useRef } from 'react';
import MediaBlock from '@modules/home/component/media-block-module/MediaBlock';
import { useHomePageBannerEventHandler } from '../../util/home-banner-tracker-helpers';

interface MediaBlockWithPositionProps {
    id: number;
    image: string;
    title: string;
    subTitle: string;
    linkLabel: string;
    linkHref: string;
    position: number; // start from 1
}

const MediaBlockBannerWrapper = (block: MediaBlockWithPositionProps) => {
    const bannerRef = useRef(null);
    const { onClick } = useHomePageBannerEventHandler(
        {
            type: 'HalfWidth',
            itemID: block.id,
            position: block.position,
        },
        bannerRef
    );

    return (
        <div ref={bannerRef}>
            <MediaBlock
                key={block.linkHref}
                onClick={onClick}
                {...block}
                advertCreative={null}
            />
        </div>
    );
};

export default MediaBlockBannerWrapper;
