import type { PillarLinkGroupProps } from '@modules/home/component/pillar-module/PillarLinkGroup';
import PillarLinkGroup from '@modules/home/component/pillar-module/PillarLinkGroup';

export interface PillarProps {
    pillarText: string;
    pillarLink?: Omit<PillarLinkGroupProps, 'isMobile'>;
    pillarContent?: string;
    lightMode?: boolean;
    isMobile: boolean;
}

const Pillar = ({
    pillarLink,
    pillarText,
    lightMode,
    isMobile,
}: PillarProps) => {
    if (!pillarLink) return null;
    return (
        <PillarLinkGroup
            {...pillarLink}
            pillarText={pillarText}
            lightMode={lightMode}
            isMobile={isMobile}
        />
    );
};

export default Pillar;
