import type { StyleRule } from '@archipro-design/aria';
import {
    buttonClassName,
    labelClassName,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';
import { hex2rgba } from '~/utils';
import { svgIconClassName } from '@archipro-design/icons';

export const Container: StyleRule = ({ theme }) => ({
    maxWidth: '100vw',
    overflow: 'auto',
    marginBottom: 0,
    padding: pxArrayToRem([10, 18]),

    '&::-webkit-scrollbar': {
        display: 'none',
    },

    [theme.screen.tablet]: {
        marginBottom: pxToRem(24),
        padding: 0,
    },
});

export const CTAButton: StyleRule = ({ theme }) => ({
    [`&.${buttonClassName}`]: {
        border: `1px solid ${theme.siteVariables.colors.primitive.black}`,

        '&:hover': {
            backgroundColor: theme.siteVariables.colors.primitive.black,
            border: `1px solid ${theme.siteVariables.colors.primitive.black}`,
        },

        [`& .${labelClassName}`]: {
            fontSize: pxToRem(13),
            lineHeight: theme.siteVariables.lineHeightSmaller,
            textTransform: 'uppercase',
        },

        [`& .${svgIconClassName}`]: {
            width: pxToRem(24),
            height: pxToRem(24),
        },
    },
});

export const NavContainer: StyleRule = ({ theme }) => ({
    paddingRight: pxToRem(18),

    [`& .${buttonClassName}`]: {
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(16),
            lineHeight: theme.siteVariables.lineHeightSmallest,
        },

        padding: pxArrayToRem([10, 10]),
        marginRight: pxToRem(19),

        [`&:not(:first-child)`]: {
            background: 'transparent',
            color: theme.siteVariables.colors.primitive.black,
            padding: pxArrayToRem([10, 0]),
            whiteSpace: 'nowrap',

            [`&:hover`]: {
                color: theme.siteVariables.colors.primitive.black,

                [`& .${labelClassName}`]: {
                    textShadow: `0 0 ${theme.siteVariables.colors.primitive.black}`,
                },
            },
        },

        [`&:last-child`]: {
            marginRight: 0,
        },
    },

    [theme.screen.tablet]: {
        paddingRight: 0,
        [`& .${buttonClassName}`]: {
            [`&:not(:first-child)`]: {
                color: hex2rgba(
                    theme.siteVariables.colorScheme.default?.foreground ??
                        theme.siteVariables.bodyColor,
                    0.6
                ),

                [`&:hover`]: {
                    color: theme.siteVariables.colorScheme.default?.foreground,
                },
            },
        },
    },
});
