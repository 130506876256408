import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const Title: StyleRule<{ shortTitle?: boolean }> = ({
    theme,
    shortTitle,
}) => ({
    fontSize: pxToRem(20),
    padding: pxArrayToRem([0, shortTitle ? 100 : 34, 0, 18]),
    lineHeight: theme.siteVariables.lineHeightDefault,
    letterSpacing: theme.siteVariables.letterSpacingNormal,
    ...theme.siteVariables.textStyles.Heading.Roman55,
    textTransform: 'uppercase',

    [theme.screen.tablet]: {
        fontSize: pxToRem(27),
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        maxWidth: pxToRem(600),
        margin: 0,
        padding: 0,
    },
});

export const Spacer: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        marginBottom: pxToRem(80),
    },
    marginBottom: pxToRem(40),
});

export const HorizontalRule: StyleRule = ({ theme }) => ({
    borderTopColor: theme.siteVariables.colors.charcoal[250],
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    marginBottom: pxToRem(70),
    marginLeft: pxToRem(50),
    marginTop: pxToRem(15),
    marginRight: pxToRem(50),
});
