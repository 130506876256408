import { Flex } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import * as S from './HeaderMenuItem.style';
import { ArrowNextLine } from '@archipro-design/icons';

interface HeaderMenuItemPros {
    label: string;
    disableAnimation?: boolean;
}

const HeaderMenuItem = ({ label, disableAnimation }: HeaderMenuItemPros) => {
    const { css } = useStyles({ disableAnimation });

    return (
        <Flex className={css(S.BaseStyle)} vAlign={'center'}>
            <div>{label}</div>
            <ArrowNextLine />
        </Flex>
    );
};

export default HeaderMenuItem;
