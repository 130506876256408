import { useStyles, Image } from '@archipro-design/aria';
import * as S from './AdPlacementModule.style';
import { useLoginModal } from '~/modules/design-board/hook/use-login-modal';
import { useUser, isUserLoggedIn } from '~/modules/user';

export interface AdPlacementModuleProps {
    image: string;
    title: string;
}

const AdPlacementModule = ({ image, title }: AdPlacementModuleProps) => {
    const { css } = useStyles();
    const { showLoginModal, loginModal } = useLoginModal({
        authSource: 'adPlacementModule',
    });
    const user = useUser();
    const isLoggedIn = isUserLoggedIn(user);

    const loggedInAction = () => {
        if (!window || typeof window === 'undefined') return;
        window.location.href = '/about-us';
    };

    const onAdClick = () => {
        return isLoggedIn ? loggedInAction() : showLoginModal();
    };

    return (
        <div className={css(S.BaseStyle)}>
            <div onClick={() => onAdClick()} className={css(S.Container)}>
                <Image
                    loading="lazy"
                    alt={title ?? ''}
                    src={image}
                    width={1030}
                    height={260}
                    layout={'fill'}
                />
            </div>
            {!isLoggedIn && loginModal}
        </div>
    );
};

export default AdPlacementModule;
